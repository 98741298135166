import bubble from "../images/booble.png";
import Cog from "../images/cog.png";
import profilepic from "../images/results/Oval.png";
import puzzle2 from "../images/results/Puzzle_2.svg";
import "../stylesheets/results-marketing.scss";

const ResultsProfileHeader = () => {
  return (
    <section className="orange-topper">
      <img className="left-puzzle-piece" alt="" src={puzzle2} />
      <article className="profile-title">Aaron L's Profile</article>
      <article className="profile-icons">
        <img className="deco-icon" alt="" src={bubble} />
        <div
          className="circle-image-container"
          style={{
            backgroundImage: `url(${profilepic})`,
          }}
        />
        <img className="deco-icon" alt="" src={Cog} />
      </article>
    </section>
  );
};

export default ResultsProfileHeader;
