import Families from "../images/familles.svg";
import Gamers from "../images/gamers.svg";
import HRTeams from "../images/hr-teams.svg";
import Professionals from "../images/professionals.svg";
import Collaborators from "../images/projects-collaborators.svg";
import ThreePieces from "../images/puzzle-three-pieces.svg";
import Students from "../images/students.svg";

const UserTypeCard = ({ image, title, text }) => {
  return (
    <div className="user-type-card">
      <div className="user-icon-container">
        <img alt="" src={image} />
      </div>
      <div className="user-type-info">
        <span className="paragraph-header">{title}</span>
        <p className="paragraph-small">{text}</p>
      </div>
    </div>
  );
};

const WhoUse = ({ element }) => {
  return (
    <section id="whouse" ref={element}>
      <div className="row main-container">
        <div className="column whouse__column">
          <span className="subtitle">Personalized</span>
          <div className="highlight-text-container">
            <span className="subsection-header">SkillProfile Is For You</span>
            <div className="blue-highlight" />
          </div>
          <p className="paragraph-big">
            SkillProfile can be used for anything where you want to learn about your knowledge,
            skills, attitudes or competencies from people around you. Below are some ideas but we’re
            sure you’ll find even more ways to use your SkillProfile. Drop us a line at
            info@skillprofile.com if you have some cool new ideas for using your own personal
            SkillProfile.
          </p>
        </div>
        <div className="puzzle-pieces">
          <img alt="" src={ThreePieces} />
        </div>
      </div>
      <div className="row top-aligned center flex-wrap">
        <UserTypeCard
          image={Gamers}
          title="Gamers"
          text="Check in with your gaming partners and friends and get anonymous feedback on your
              playstyle and approach."
        />
        <UserTypeCard
          image={HRTeams}
          title="HR Teams"
          text="Do you want to simplify your 360 feedback gathering and put it in the employee's
              hands? Use SkillProfile to put your employees in control."
        />
        <UserTypeCard
          image={Professionals}
          title="Professionals"
          text="Curious how your skills are perceived by your peers? Use SkillProfile to solicit
              anonymous feedback from team members you trust."
        />
        <UserTypeCard
          image={Students}
          title="Students"
          text="You're learning, you're growing. Get real time feedback from a variety of sources on
              how you're performing and what you're good at!"
        />
        <UserTypeCard
          image={Collaborators}
          title="Projects Collaborators"
          text="Working on a project and want to make sure you're adding the most value where you
              think you are?"
        />
        <UserTypeCard
          image={Families}
          title="Familes"
          text="You don't get to pick your family but you do get to pick how you engage with them. Get
              feedback from family on how you fit in!"
        />
      </div>
      <article className="skillprofile-version">
        Skill Profile Version 17.0, March the 21st 2022 A.D.
      </article>
    </section>
  );
};

export default WhoUse;
