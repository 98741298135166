import ProfileCard from "../components/ProfileCard";

const FeedbackPageAttributeList = (props) => {
  const attributes = props.attributes;
  const ratings = props.ratings;
  const user = props.user;
  const username = user ? user.fullName : "Bagel";
  const handleSliderChange = props.handleSliderChange;
  const shouldDisplayAsResults = props.shouldDisplayAsResults;

  const getTitle = (attribute) => {
    const title = shouldDisplayAsResults ? attribute.AttributeType.toLowerCase() : attribute.type;

    return title;
  };

  const getValue = (attribute, index) => {
    const value = shouldDisplayAsResults ? attribute.AverageScore : ratings[index].rating;

    return value;
  };

  return (
    <>
      {attributes.map((attribute, index) => (
        <ProfileCard
          key={index}
          title={getTitle(attribute)}
          attribute={attribute}
          handleChange={handleSliderChange}
          value={getValue(attribute, index)}
          username={username}
          index={index}
          shouldDisplayAsResults={shouldDisplayAsResults}
        />
      ))}
    </>
  );
};

export default FeedbackPageAttributeList;
