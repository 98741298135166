import { db } from "../firebase/firebase-init";

const getUserByUid = async (userUid) => {
  try {
    if (userUid) {
      const res = await db.collection("Users").where("uid", "==", userUid).get();

      if (res.empty) return undefined;

      const user = res.docs[0].data();

      if (user.uid !== userUid) return undefined;

      return user;
    }
  } catch (error) {
    console.error(`user-service couldn't load from Users where uid=${userUid}: `, error);
    throw error;
  }
};

const getUserById = async (userId) => {
  try {
    const res = await db.collection("Users").doc(userId).get();
    const user = res.data();

    return user;
  } catch (error) {
    console.error(`user-service couldn't load from Users where id=${userId}: `, error);
    throw error;
  }
};

const getUserByEmail = async (email) => {
  try {
    if (email) {
      const res = await db.collection("Users").where("email", "==", email).get();

      if (res.empty) return undefined;

      const user = res.docs[0].data();

      if (user.email !== email) return undefined;

      return user;
    }
  } catch (error) {
    console.error(`user-service couldn't load from Users where email=${email}: `, error);
    throw error;
  }
};

export { getUserById, getUserByUid, getUserByEmail };
