const AppleStoreButton = () => {
  return (
    <div className="download-button apple">
      <a
        href="https://apps.apple.com/us/app/skillprofile/id1584421945?itsct=apps_box_badge&amp;itscg=30200"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="Download on the App Store"
          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?&amp;releaseDate=1642032000&h=5a7fa94718690fc004b3627ec23541b3"
        />
      </a>
    </div>
  );
};

export default AppleStoreButton;
