import { Link } from "react-router-dom";
import Check from "../images/check.svg";
import ScreenTwo from "../images/screen-2.png";

const ListItem = ({ title, text }) => {
  return (
    <div>
      <div className="check-and-title">
        <img alt="" src={Check} />
        <span className="paragraph-header dark-bg left-align">{title}</span>
      </div>
      <p className="three-column dark-bg home-page__copy">{text}</p>
    </div>
  );
};

const YourResults = ({ element }) => {
  return (
    <section id="your-results" className="column center dark-bg" ref={element}>
      <span className="subtitle dark">Show Off</span>
      <span className="highlight-text-container">
        <span className="subsection-header dark">Your Results</span>
        <div className="orange-highlight short" />
      </span>

      <div className="row main-container dark-bg top-aligned">
        <div className="column spread your-results__text-container left">
          <ListItem
            title="Improve Your Skills"
            text="Use your results to better understand what attributes are considered your strengths
              and weaknesses and use SkillProfile monthly to monitor your progress as you learn and
              practice new behaviors or gain new knowledge and skills."
          />
          <ListItem
            title="Keywords"
            text="Pick from thousands of attributes pre-loaded into the app or feel free to create your
              own. Use SkillProfile to capture ratings on any sort of knowledge, skill, attitude
              regardless of where you use those. You’ll be surprised by the areas of your life you
              can learn about through SkillProfile."
          />
        </div>

        <div className="column center your-results__image-container">
          <div className="image-center">
            <img src={ScreenTwo} alt="your results" />
          </div>
          <Link target="_blank" to="/welcome" className="button-long orange get-the-app">
            Get The App
          </Link>
        </div>

        <div className="column spread your-results__text-container right">
          <ListItem
            title="Share Your Results"
            text="Share your results on Social Media or anywhere you want someone to know your ratings.
              Or keep it to yourself for personal growth. The choice is yours."
          />
          <ListItem
            title="Comments From Users"
            text="People providing your ratings can also add specific comments and choose whether they
              can be shown in their entirety or grouped together with other respondents. Aggregated
              comments are used to create a personalized word cloud for your profile."
          />
        </div>
      </div>
    </section>
  );
};

export default YourResults;
