import "../stylesheets/results-marketing.scss";

const ResultsPageHeader = () => {
  return (
    <section id="results-header">
      <div className="highlight-text-container">
        <span className="section-header">
          Share Your Results,
          <br />
          or Keep Them Private
        </span>
        <div className="orange-highlight" />
      </div>
    </section>
  );
};

export default ResultsPageHeader;
