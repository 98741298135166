import { auth, db, firebase } from "../firebase/firebase-init";

const registerUser = async (userInput) => {
  const { fullName, email, password } = userInput;
  try {
    const res = await db.collection("Users").where("email", "==", email).get();
    const users = res.docs.map((doc) => doc.data()).filter((data) => data.email === email);
    if (users.length > 0) {
      return {
        success: false,
        message: "A user account is already associated with that email.",
      };
    } else {
      const authorizationData = await auth.createUserWithEmailAndPassword(email, password);
      const created = firebase.firestore.FieldValue.serverTimestamp();
      const subscription = "inactive";
      const givenName = fullName.split(" ")[0];
      const uid = authorizationData.user.uid;

      await db.collection("Users").add({
        created,
        email,
        fullName,
        subscription,
        givenName,
        uid,
      });

      return {
        success: true,
        userId: authorizationData.user.userId,
        userGivenName: givenName,
        message: `User ${authorizationData.user.email} registered successfully.`,
      };
    }
  } catch (error) {
    console.error(`auth-service couldn't process a new registration.`, error);

    return {
      success: false,
      message: "An error occurred during registration. Please try again.",
    };
  }
};
export { registerUser };
