import Facebook from "../images/Facebook.svg";
import Google from "../images/google.svg";
import ScreenOne from "../images/screen-1.svg";

const GetStarted = ({ element }) => {
  return (
    <section id="getstarted" className="row getstarted__row" ref={element}>
      <div className="getstarted__image">
        <img className="responsive_image" src={ScreenOne} alt="Screen 1" />
      </div>
      <div className="column">
        <span className="blue-title">Easy to Start</span>
        <span className="highlight-text-container">
          <span className="subsection-header">Create a SkillProfile®</span>
          <div className="blue-highlight" />
        </span>

        <p className="home-page__copy">
          Download the app and create your SkillProfile by selecting the attributes you want to ask
          about. Select from the thousands of existing attributes or create your own. Your first
          profile is free forever and includes a monthly check-in and feedback request. Subscribe to
          get unlimited profile and feedback requests!
        </p>
        <div className="simple-flex-row">
          <div className="start-icon">
            <img className="responsive_image" alt="Google Logo" src={Google} />
          </div>
          <div className="simple-flex-column">
            <span className="paragraph-header">Start With Google Account</span>
            <p className="sharing-snippet">
              Connect with your Google Account to ask for feedback from your email contacts
            </p>
          </div>
        </div>

        <div className="simple-flex-row">
          <div className="start-icon">
            <img className="responsive_image" src={Facebook} alt="Get Started" />
          </div>
          <div className="simple-flex-column">
            <span className="paragraph-header"> Start With Facebook Account</span>
            <p className="sharing-snippet">
              Connect with hundreds by signing up the world's largest social network
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetStarted;

/**
 * Connect with all of your gaming friends, gaming rivals,
 * frienemies, and random noobs you pwned by signing up with your Steam account!
 */
