import { Link } from "react-router-dom";
import NavBar from "../components/NavBar";
import "../stylesheets/about.scss";

const AboutPage = () => {
  const URL_VIDEO = "https://www.youtube.com/embed/CLzLRXyv0jY?si=RnFKXTFdNs994-ix";

  return (
    <>
      <NavBar />
      <section id="about">
        <article className="highlight-text-container">
          <span className="section-header">The Inspiration</span>
          <div className="orange-highlight" />
        </article>
        <article className="copy-container">
          <p>
            It all started in a prep session for the SPHR test. I was having lunch with a professor
            and several students and thought leaders form the HR space; we were discussing how
            competencies should be used, and why they weren’t being adopted.
          </p>
          <p>In January of 2010, I bought the skillprofile.com domain.</p>
          <p>
            It always bothered me that competencies felt very impersonal. Why did everything to do
            with my skills, knowledge and attitudes somehow come back to my employer? I am more than
            just my job, and those attributes are personal.
          </p>
          <p>
            How can I take ownership of my own performance management, independent of my employment
            status? Coaches always say “Lead with your Strengths”... but what if you don’t know what
            those are?
          </p>
          <p>
            <strong>Many people really have no idea of what they’re good at.</strong>
          </p>
          <p>
            Millenials and Gen Z are demanding regular feedback. The past few years with
            Coronavirus, the Great Resignation, and the advent of work mobility and artificial
            intelligence have permanently changed the workplace and employment landscape. It's time
            to apply that change to performance management -- and make it personal.
          </p>
          <p>
            Enter <strong>SkillProfile®.</strong>"
          </p>
        </article>
        <article className="video-container">
          <iframe
            width="560"
            height="315"
            src={URL_VIDEO}
            title="How well do YOU know yourself?"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </article>
        <article className="button-container">
          <Link className="button-long" to="/terms" target="_blank">
            Terms of Service
          </Link>
          <Link className="button-long" to="/privacy" target="_blank">
            Privacy
          </Link>
        </article>
      </section>
    </>
  );
};

export default AboutPage;
