import { Link } from "react-router-dom";
import NavBar from "../components/NavBar";
import "../stylesheets/legal.scss";

const Terms = () => {
  return (
    <>
      <NavBar />
      <section id="terms">
        <div className="highlight-text-container align-self-center">
          <span className="section-header center">Terms of Service</span>
          <div className="orange-highlight" />
        </div>
        <h2 className="subsection-header">Effective Date: June 25, 2021</h2>
        <p className="legal-justified">
          Welcome to www.skillprofile.com (the “Website”), owned and operated by SkillProfile, Inc.,
          a Delaware corporation (“SP”, “we”, “us”, or “our”). Except as otherwise noted herein,
          these terms and conditions (the “Terms”) govern your use of the Website, the SP app (the
          “App”), and SP’s services, content and products (collectively, the “Service”). Please read
          these Terms carefully because your use of the Service constitutes your agreement to follow
          and be bound by these Terms. If you do not agree to these Terms, you should not access or
          use the Service. By using the Service, you acknowledge, accept and agree with all
          provisions of the <Link to="/privacy">Privacy Policy</Link>, including, without
          limitation, the use and treatment of the text and other data you choose to input, upload,
          or store in SP (collectively, “Content”) and your Personal Information in accordance with
          the <Link to="/privacy">Privacy Policy</Link>.
        </p>
        <p className="legal-justified">
          SP reserves the right to make changes to the Service and to these Terms from time to time.
          When we make changes, we will post them here. For this reason, we encourage you to review
          these Terms whenever you use our Service because by visiting the Service, you agree to
          accept any such changes. SP provides you with access to and use of the Service subject to
          your compliance with these Terms. No material from the Service may be copied, reproduced,
          republished, uploaded, posted, transmitted or distributed in any way, except as
          specifically permitted on the Service. The Service, including all of its information and
          content, such as text, data, wallpaper, icons, characters, artwork, images, photographs,
          graphics, music, sound, messages, software and the HTML used to generate the pages
          (collectively, “Materials and Content”), is SP’s property or that of our suppliers or
          licensors and is protected by patent, trademark and/or copyright under United States
          and/or foreign laws. Except as otherwise provided on the Service or in these Terms, you
          may not use, download, upload, copy, print, display, perform, reproduce, publish, modify,
          delete, add to, license, post, transmit or distribute any Materials and Content from this
          Service in whole or in part, for any public or commercial purpose without the specific
          prior written permission of SP. We grant you a personal, limited, nonexclusive,
          nontransferable license to access the Service and to use the information and services
          contained therein solely for your personal, noncommercial use as described below.{" "}
        </p>
        <p className="legal-justified">
          We reserve the right, for any reason or for no reason, in our sole discretion and without
          notice to you, to revise the products and services described on the Service and to
          terminate, change, suspend or discontinue any aspect of the Service, including, but not
          limited to, the Materials and Content on the Service as well as features and/or hours of
          availability of the Service, and we will not be liable to you or to any third party for
          doing so. We may also impose rules for and limits on use of the Service or restrict your
          access to part, or all, of the Service without notice or penalty. We have the right to
          change these rules and/or limitations at any time, in our sole discretion.
        </p>
        <p className="legal-justified">
          PLEASE READ THESE TERMS CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. THESE
          TERMS CONTAIN A MANDATORY INDIVIDUAL ARBITRATION AND CLASS ACTION/JURY TRIAL WAIVER
          PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES,
          RATHER THAN JURY TRIALS OR CLASS ACTIONS.
        </p>
        <ol className="ordered">
          <li key="acctcreation">
            <p>
              <span className="underlined">Account Creation; Eligibility.</span> This is a contract
              between you and SP. You must read and agree to these Terms before using the Service.
              If you do not agree, you may not use the Service to register for an account. You may
              use the Service only if you can form a binding contract with SP, and only in
              compliance with these Terms and all applicable local, state, national, and
              international laws, rules and regulations. In order to purchase from the Service you
              must complete the registration process to obtain a user account. You will be required
              to provide your
              <span className="highlighted">
                {" "}
                name, address, e-mail address, phone number and date of birth.
              </span>{" "}
              You must provide complete and accurate information about yourself during the
              registration process and you have an ongoing obligation to update this information if
              and when it changes. We will handle your information consistent with our Privacy
              Policy, which is incorporated into these Terms by reference. When you create an
              account, you will be required to create a user ID and password. You agree that you
              will never share your user ID and password with anyone else for any reason. You agree
              that only you will use your account. You agree to contact SP immediately in the event
              you become aware of unauthorized access to your account. You agree that you are solely
              responsible for all activity that takes place in connection with your account and you
              agree to indemnify and hold harmless SP from any damages that arise out of or in
              relation to use of your account. You agree that you will not create more than one
              account. By registering and obtaining an account you affirm that you will follow the
              Terms and your registration constitutes your consent to enter into agreements with us
              electronically.
            </p>
          </li>
          <li key="easteregg">
            <p>
              <span className="underlined">Fees and Payment.</span> Where you sign up for paid
              access to the Service (a “Subscription”) through the Website or App, you must provide
              credit card or PayPal account information. All Subscription Fees are due in advance,
              and Subscriptions will automatically renew (and you will be charged) monthly unless
              you cancel your account. Subscription Fees will be refunded only if you cancel your
              Subscription within thirty (30) days of when payment is due. We will inform you prior
              to your Subscription renewing. We charge sales tax where required. By submitting
              payment, you consent to the terms of use and privacy policy of the relevant payment
              processor (e.g., Stripe). Payment practices of App Stores may differ according to
              their terms.
            </p>
            <p>
              <span className="underlined">Subscription.</span> Certain features of the Service are
              only available with a paid access plan (a “Subscription”). Subscription levels,
              features, and pricing are available at [www.skillprofile.com/]. Fees for Subscriptions
              (“Subscription Fees”) are due in advance for each billing period, with the initial
              payment due when you add a Subscription to your account (the “Subscription Start
              Date”). Except as expressly stated in these Terms, Subscription Fees are
              non-refundable and are based on our standard charges and billing terms in effect when
              payment is due. You must provide us with a valid credit card or other payment method
              we accept (“Payment Provider”) prior to starting a Subscription and promptly update
              your account if there is any change to your payment information. Your Payment Provider
              agreement, not these Terms, governs your use of your designated Payment Provider. We
              may change our fees and billing practices at any time, either by posting notice of
              such change on the Website or notifying you within the Services, provided that any
              such change will not apply to you until the next Subscription period or if you change
              your Subscription type.{" "}
            </p>
            <p>
              <span className="underlined">Automatic Renewal.</span> Subscriptions will
              automatically renew at the rates then in effect, AND YOUR PAYMENT PROVIDER WILL BE
              CHARGED UPON RENEWAL UNLESS YOU CANCEL YOUR SUBSCRIPTION as set forth below. By
              subscribing, you authorize us to charge your Payment Provider at the beginning of any
              Subscription period if you have not cancelled your account by such time. If we cannot
              process payment from your Payment Provider, we will reach out to you to update your
              Payment Provider information or make other arrangements for payment. If we do not
              receive payment within fifteen (15) days of the renewal, we may terminate your
              Subscription.
            </p>
            <p>
              <span className="underlined">Cancellation.</span> Subscriptions may be cancelled at
              any time by emailing us at info@skillprofile.com You will be able to use any
              Subscription you cancel until its scheduled expiration, but you will not be eligible
              for any pro-rata refund of the current term. However, we will refund the full
              Subscription Fee if you request a refund within thirty (30) days of the Subscription
              Start Date or any Subscription renewal date.{" "}
            </p>
            <p>
              <span className="underlined">Taxes.</span> Any sales, use, value added, or other tax
              based on the Subscription that we are required to pass through to customers is
              automatically calculated and added to all orders where applicable (except for users in
              the European Economic Area, (“EEA”) where VAT is included in the amount charged to you
              by SP). To the extent applicable, all payments for the Services will be made free and
              clear of, and without reduction for, any withholding taxes. Sales tax is the sole
              responsibility of the user.
            </p>
            <p>
              <span className="underlined">Payment Processors.</span> We use Stripe (“Payment
              Processor”) for payment services (e.g., card acceptance, merchant settlement, and
              related services). By paying for a Subscription, you agree to be bound by the
              applicable Payment Processor’s terms and policies, and authorize us and the Payment
              Processor to share any information and payment instructions you provide with your
              Payment Provider and any other Third-Party Service provider(s) solely as required to
              complete your transactions.
            </p>
          </li>
          <li>
            <p>
              <span className="underlined">Termination</span>
            </p>
            <p>
              <span className="underlined">Term. </span>These Terms take effect when you accept them
              as set forth above and will remain in force as long as you have an account or use the
              Service
            </p>
            <p>
              <span className="underlined">Termination by SP.</span> If we cannot charge your
              Payment Provider, if you materially breach these Terms, or if required to do so by law
              (e.g., where the provision of the Services becomes unlawful), we may (but are not
              required to) suspend or terminate your Subscription and/or account, with or without
              notice. Termination under this section is at SP’s sole discretion, and we will not be
              liable to you or any third party for any such termination.
            </p>
            <p>
              <span className="underlined">Termination by You.</span> You may terminate a
              Subscription at any time as set forth in Section 2. To delete your account, please
              email info@skillprofile.com. Except as specifically described above, we will not
              provide any refunds for terminated Subscriptions. If you close your account, these
              Terms will no longer apply to you except to the extent you visit the Website and for
              any provisions that survive termination specified below.
            </p>
          </li>
          <li>
            <p>
              {" "}
              <span className="underlined">Electronic Communications.</span> With the creation of an
              account you agree that we may send to you electronic notices or other communication
              regarding this Service. These types of electronic communications will be sent to the
              email address that was provided during registration and/or the email address
              associated with your account. The contents of any communication are effective when
              sent, regardless of when you receive or whether you read the communication. You can
              stop receiving these notices and communications at any time with the deactivation of
              your account. By providing your email, you are representing you are of legal age to
              receive electronic communications and may receive electronic communications and
              exclusive offers. These exclusive offers may be unavailable depending on your state
              and local regulations. All offers are Void Where Prohibited. The email address
              provided will be used solely by SP and will not be sold to, shared with, or otherwise
              disclosed to other parties, except as permitted under our Privacy Policy. You can
              choose to stop receiving offers at any time by filling out an unsubscribe request,
              using the unsubscribe option on communications or contacting us using the contact
              information on the Service.
            </p>
          </li>
          <li>
            <p>
              <span className="underlined">Prohibited Uses.</span> The Service may be used only for
              lawful purposes and is available only for your personal, noncommercial use, which
              shall be limited to viewing the Service, purchasing services, providing information to
              the Service and downloading product information for your personal review. You are
              responsible for your own communications, including the transmission, posting and
              uploading of information, and are responsible for the consequences of such
              communications to the Service. SP specifically prohibits any use of the Service, and
              requires all users to agree not to use the Service, for any of the following: (i)
              posting any information that is incomplete, false, inaccurate or not your own; (ii)
              engaging in conduct that would constitute a criminal offense, giving rise to civil
              liability or otherwise violate any city, state, national or international law or
              regulation, or that would fail to comply with accepted Internet protocol; (iii)
              communicating, transmitting or posting material that is copyrighted or otherwise owned
              by a third party unless you are the copyright owner or have the irrevocable permission
              of the owner to post it; (iv) communicating, transmitting or posting material that (a)
              reveals trade secrets, unless you own them or have the irrevocable permission of the
              owner, (b) infringes on any other intellectual property, privacy or publicity right of
              another, or (c) is in violation of applicable laws or regulations; (v) communicating,
              transmitting or transferring (by any means) information or software derived from the
              Service to foreign countries or certain foreign nations in violation of any applicable
              export control laws; or (vi) attempting to interfere in any way with the Service’s or
              SP’s networks or network security, or attempting to use the Service’s service to gain
              unauthorized access to any other computer system.
            </p>
          </li>
          <li>
            <p>
              <span className="underlined">Security Rules.</span> Violations of system or network
              security may result in civil or criminal liability. SP investigates violations and may
              involve, and cooperate with, law enforcement authorities in prosecuting any user or
              users who are involved in such violations. You are prohibited from violating or
              attempting to violate the security of the Service, including, without limitation, the
              following: (i) accessing data not intended for you or logging on to a SP server or
              account that you are not authorized to access; (ii) attempting to probe, scan or test
              the vulnerability of a system or network or to breach security or authentication
              measures without proper authorization (or succeeding in such an attempt); (iii)
              attempting to interfere or interfering with the operation of our Service, our
              provision of services to any other visitors to our Service and our hosting provider or
              our network, including, without limitation, via means of submitting a virus to the
              Service, overloading, “flooding,” “email bombing” or “crashing” the Service; and (iv)
              forging any TCP/IP packet header or any part of the header information in any email or
              transmission or posting to our Service.
            </p>
          </li>
          <li>
            <p>
              <span className="underlined">Pricing Information.</span> Although SP has made every
              effort to display our services and products and the features, specifications and
              colors thereof as accurately as possible, the displayed colors of the products depend
              upon the monitor of the user, and SP cannot guarantee that the user’s monitor will
              accurately portray the actual colors of the products. Prices are subject to change. SP
              is not responsible for typographical errors regarding price or any other matter.
            </p>
          </li>
          <li>
            <p>
              <span className="underlined"> </span>User License Grant; Social Media Agreement.
            </p>
            <ol type="a">
              <li>
                <p>
                  We do not claim ownership of user-generated content and material. Any and all
                  photographs, articles, images, graphics, videos, sounds, music, audio recordings,
                  text, files, profiles, communications, comments, feedback, suggestions, ideas,
                  concepts, questions, data or other content that you: (i) submit or post on the
                  Service, on any of our blogs, social media accounts or through tools or
                  applications we provide for posting or sharing such content with us; or (ii) have
                  posted or uploaded to your social media accounts, including but not limited to
                  Instagram, Twitter, Facebook, Tumblr and Pinterest, that are tagged with
                  #skillprofile, #, # or any other SP promoted hashtag (collectively “User Content”)
                  shall be deemed non-confidential and non-proprietary. By submitting or posting any
                  User Content, you grant to SP a perpetual, irrevocable, royalty-free, worldwide,
                  sublicensable and transferable license to copy, publish, translate, modify,
                  reformat, create derivative works from, distribute, reproduce, sell, display,
                  transmit, publish, broadcast, host, archive, store, cache, use or otherwise
                  exploit all or any portion of the User Content, as well as your name, persona and
                  likeness (and the likeness of any other person appearing therein, including,
                  without limitation, any minor child) included in any User Content and your social
                  media account handle, username, real name, profile picture and/or any other
                  information associated with the User Content, in any commercial or noncommercial
                  manner whatsoever, in whole or in part, in any and all distribution channels,
                  forms, media or technology, whether now known or hereafter developed, including,
                  but not limited to, in stores, printed marketing materials, emails, web pages,
                  social media accounts and for any other marketing, advertising, public relations,
                  sales or promotional purposes with or without attribution and without further
                  notice to you. Neither you, nor any other person or entity, will have the right
                  to: (a) receive any royalty or consideration of any kind for the use of the User
                  Content pursuant to these Terms; or (b) inspect or approve the editorial copy or
                  other material that may be used in connection with the User Content. SP will be
                  free to use any ideas, concepts, know-how or techniques contained in such User
                  Content for any purpose whatsoever, including, but not limited to, developing,
                  manufacturing and marketing products and services that incorporate or otherwise
                  rely upon such information. SP shall have no obligation to monitor User Content,
                  use or display User Content, compensate you for submitting User Content or respond
                  to any User Content. SP retains the right, in its sole discretion and without
                  prior notice, to remove, revise or refuse to post any User Content for any reason
                  or no reason. Subject to the licenses granted in these Terms, you retain ownership
                  of any copyright and other rights you may have in the User Content.
                </p>
              </li>
              <li>
                <p>
                  By submitting or posting User Content on the Service, on your social media
                  accounts or through any tools or applications we provide for posting or sharing
                  your User Content with us, you represent and warrant that: (i) you own or control
                  any and all rights in and to the User Content, and the right to grant all of the
                  rights and licenses in these Terms, and if you are not the holder of such rights,
                  the holder of such rights has completely and effectively waived all such rights
                  and irrevocably granted you the right to grant the licenses stated above without
                  the need for payment to you or any other person or entity; (ii) you have obtained
                  permission from any individuals that appear in the User Content to use, and grant
                  others the right to use, their name, image, voice and/or likeness without the need
                  for payment to you or any other person or entity; (iii) you hereby give permission
                  on behalf of any minor children that appear in the User Content, to which you are
                  the legal guardian, to use, and grant others the right to use, their name, image,
                  voice and/or likeness without the need for payment to you or any other person or
                  entity; (iv) you are 18 years of age or older; and (v) the User Content does not
                  (a) contain false or misleading information, (b) infringe on the intellectual
                  property, privacy, publicity, statutory, contractual or other rights of any third
                  party, (c) contain any libelous, defamatory, obscene, offensive, threatening or
                  otherwise harassing or hateful content, (d) contain any addresses, email
                  addresses, phone numbers or any contact information or (e) contain computer
                  viruses, worms or other harmful files. Upon request by SP, you will furnish SP any
                  documentation, substantiation or releases necessary to verify your compliance with
                  these Terms. You are solely responsible for the User Content and you hereby agree
                  to indemnify and hold SP and its members, manager, employees, agents, affiliates,
                  assigns and licensees harmless from any and all damages, claims, expenses, costs
                  or fees arising from or in connection with a breach of any of the foregoing
                  representations or your violation of any law or rights of a third party.
                </p>
              </li>
              <li>
                <p>
                  SP does not guarantee the truthfulness, accuracy or reliability of any User
                  Content or endorse any opinions expressed by you or anyone else. By submitting or
                  posting the User Content you fully and unconditionally release and forever
                  discharge SP and its officers, directors, employees and agents from any and all
                  claims, demands and damages (actual or consequential, direct or indirect), whether
                  now known or unknown, of every kind and nature relating to, arising out of or in
                  any way connected with: (i) disputes between you and one or more users or any
                  other person or entity; or (ii) the use by SP or you of the User Content,
                  including, without limitation, any and all claims that use of the User Content
                  pursuant to these Terms violates any of your intellectual property rights,
                  copyrights, rights of publicity or privacy, “moral rights,” or rights of
                  attribution and integrity. You acknowledge and agree that SP has no control over,
                  and shall have no liability for any damages resulting from, the use (including,
                  without limitation, re-publication) or misuse by you or any third party of any
                  User Content. SP acts as a passive conduit for User Content and has no obligation
                  to screen or monitor User Content. If SP becomes aware of any User Content that
                  allegedly may not conform to these Terms, SP may investigate the allegation and
                  determine in its sole discretion whether to take action in accordance with these
                  Terms. SP has no liability or responsibility to Users for performance or
                  nonperformance of such activities.
                </p>
              </li>
              <li>
                <p>
                  Without limiting the foregoing in any way, SP has the absolute right to remove
                  and/or delete without notice any User Content within its control in its sole
                  discretion. You consent to such removal and/or deletion and waive any claim
                  against SP for such removal and/or deletion. SP is not responsible for failure to
                  store posted content or other materials you transmit through the Service. You
                  should take measures to preserve copies of any data, material, content or
                  information you post to the Service or any other sites or platforms.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <span className="underlined">Privacy Policy</span> Notwithstanding anything else to
              the contrary contained in these Terms of Use, SP’s use of any personally identifiable
              information (name, etc.) you provide via the Service shall be governed by our Privacy
              Policy. For further information regarding SP’s protection of your personal
              information, please refer to our <Link to="/privacy">Privacy Policy</Link>
            </p>
          </li>
          <li>
            <p>
              <span className="underlined">California Consumer Rights Notice. </span>
              Under California Civil Code Section 1789.3, California users are entitled to the
              following consumer rights notice: If you have a question or complaint regarding the
              Service, please either send an email to info@skillprofile.com or write to us at
              SkillProfile Inc., 3031 Tisch Way, San Jose, CA 95128. Residents of California may
              contact the California Department of Consumer Affairs Division of Consumer Services,
              Complaint Assistance Unit by mail at 1625 North Market Blvd., Sacramento, CA 95834, or
              by telephone at (916) 445-1254 or (800) 952-5210.
            </p>
          </li>
          <li>
            <p>
              <span className="underlined">Content Rights.</span> While you own the Content you
              store within the Service (subject to third party rights), you acknowledge and agree
              that SP (and our licensors) own(s) all legal right, title and interest in and to the
              Service, including, without limitation, all software that is part of the Service and
              all SP software deployed by you or a third party to enable capturing of Content
              originating outside the Service or any of the SP software applications for compatible
              computing devices that enable access and use of the Service through such device.
            </p>
          </li>
          <li>
            <p>
              <span className="underlined">Proprietary Rights.</span> As between you and SP, SP is
              the owner and/or authorized user of any registered or unregistered trademark, trade
              name and/or service mark appearing on the Service, and is the copyright owner or
              licensee of the Materials and Content on the Service, unless otherwise indicated. The
              SP logos, designs, titles, phrases and product names and the copyrights, trademarks,
              service marks, trade dress and/or other intellectual property in such materials
              (collectively, the “Intellectual Property”) are owned by SP and may be registered in
              the United States and internationally. You agree not to display or use the
              Intellectual Property in any manner without SP’s prior written permission. Nothing on
              the Service should be construed to grant any license or right to use any of the
              Intellectual Property without the prior written consent of SP. Except as otherwise
              provided herein, use of the Service does not grant you a license to any Materials and
              Content or features you may access on the Service and you may not modify, rent, lease,
              loan, sell, distribute or create derivative works of such Materials and Content,
              features or materials, in whole or in part. Any commercial use of the Service is
              strictly prohibited, except as allowed herein or otherwise approved by us. You may not
              download or save a copy of any of the Materials and Content or screens for any purpose
              except as otherwise provided by SP. If you make use of the Service, other than as
              provided herein, in doing so you may violate copyright and other laws of the United
              States and/or other countries, as well as applicable state laws, and you may be
              subject to liability for such unauthorized use. The information on the Service,
              including, without limitation, all site design, text, graphics, interfaces and the
              selection and arrangements of such is protected by law, including, but not limited to,
              copyright law.
            </p>
          </li>
          <li>
            <p>
              <span className="underlined">Right to Modify the Service</span>We retain the right, in
              our sole discretion, to implement new elements as part of and/or ancillary to the
              Service, including changes that may affect the previous mode of operation of the
              Service. We expect that any such modifications will enhance the overall Service, but
              it is possible that you may not agree with us. We also reserve the right to establish
              limits to the nature or size of storage available to you, the number of transmissions,
              the ability to send or receive email messages, the nature or size of any index or
              library information, the nature of, or your continued ability to access or distribute,
              your Content and other data, and impose other limitations at any time, with or without
              notice. You also acknowledge that a variety of SP actions may impair or prevent you
              from accessing your Content or using the Service at certain times and/or in the same
              way, for limited periods or permanently, and agree that SP has no responsibility or
              liability as a result of any such actions or results, including, without limitation,
              for the deletion of, or failure to make available to you, any Content. You agree that
              we shall not be liable to you or to any third party for any modification, suspension
              or discontinuance of any part of the Service. However, if you are a paid subscriber of
              SP through a Subscription and find that any such modifications or interruption of the
              Service adversely affects you, you may notify our customer support team, explain the
              adverse impact the modification has created and, if you desire, request a termination
              of your Subscription. Upon receipt of any such request, we will endeavor to promptly
              remedy the adverse impact caused by the modification, extend the duration of your
              Subscription for a period of time equal to the interruption and/or refund a portion of
              your Subscription fee equal to the remaining unused term of the Subscription, as we
              determine appropriate or as may be required by applicable law.
            </p>
          </li>
          <li>
            <p>
              <span className="underlined">Right to Engage Third Parties.</span> SP engages certain
              affiliates or other third parties (“Service Providers”) to provide technical or other
              services relating to all or part of the Service, and you hereby agree that such
              involvement by these Service Providers is acceptable. In addition, SP may contract
              with third party resellers of the Subscription and payment processors to enable
              payments in your local currency and payment systems. Please see our{" "}
              <Link to="/privacy">Privacy Policy</Link> to understand the extent to which any
              affiliate or third party may have access to your account information or Content.
            </p>
          </li>
          <li>
            <p>
              <span className="underlined">Right to Update Our Service.</span> In connection with
              any modification of the Service, SP may automatically download software updates on
              your computers and devices from time to time with the intention of improving,
              enhancing, repairing and/or further developing the Service. SP will endeavor to
              provide you with the option of whether or not to install the update; however, in
              certain circumstances (e.g., security risks), SP may require you to install the update
              to continue accessing the Service. In all cases, you agree to permit SP to deliver
              these updates to you (and you to receive them) as part of your use of the Service.
            </p>
          </li>
          <li>
            <p>
              <span className="underlined">Copyright Complaints.</span> If you believe that your
              work has been copied in a way that constitutes copyright infringement, please provide
              SP’s copyright agent the following information required by the Online Copyright
              Infringement Liability Limitation Act of the DMCA, 17 U.S.C. §512 (“DMCA”). Please be
              advised that to be effective, the notice must include ALL of the following: (i) a
              physical or electronic signature of a person authorized to act on behalf of the owner
              of an exclusive right that is allegedly infringed; (ii) identification of the
              copyright work claimed to have been infringed, or, if multiple copyrighted works at a
              single online site are covered by a single notification, a representative list of such
              works at that site; (iii) identification of the material that is claimed to be
              infringing or to be the subject of infringing activity and information reasonably
              sufficient to permit us to locate the material; (iv) information reasonably sufficient
              to permit us to contact the complaining party; (v) a statement that the complaining
              party has a good-faith belief that use of the material in the manner complained of is
              not authorized by the copyright owner, its agent or as a matter of law; and (vi) a
              statement that the information in the notification is accurate, and under penalty of
              perjury, that the complaining party is authorized to act on behalf of the owner of an
              exclusive right that is allegedly infringed. Notices of claimed copyright infringement
              and counter-notices should be delivered via email to info@skillprofile.com or by mail
              addressed to SkillProfile Inc., 3031 Tisch Way, San Jose, CA 95128. In accordance with
              the DMCA, it is our policy to terminate use of our Service by repeat infringers in
              appropriate circumstances.
            </p>
          </li>
          <li>
            <p>
              <span className="underlined">Third Party Links.</span> The Service may contain links
              to websites, applications or other products or services operated by other companies
              (“Third Party Platforms”). SP does not endorse, monitor or have any control over these
              Third Party Platforms, which have separate terms of use and privacy policies. SP is
              not responsible for the content or policies of Third Party Platforms and you access
              such Third Party Platforms at your own risk.
            </p>
          </li>
          <li>
            <p>
              <span className="underlined">Disclaimers and Limitations of Liability.</span>{" "}
            </p>
            <ol type="a">
              <li>
                <p>
                  SP publishes information on its Service as a convenience to its visitors. While SP
                  attempts to provide accurate and timely information, there may be inadvertent
                  technical or factual inaccuracies and typographical errors. We reserve the right
                  to make corrections and changes to the Service at any time without notice. The
                  products described on the Service may not be available in your region. SP does not
                  claim that the information on the Service is appropriate to your jurisdiction or
                  that the products described on its Service will be available for purchase in all
                  jurisdictions.
                </p>
              </li>
              <li>
                <p>
                  You assume all responsibility and risk with respect to your use of the Service,
                  which is provided “AS IS.” SP DISCLAIMS ALL WARRANTIES, CONDITIONS,
                  REPRESENTATIONS AND ENDORSEMENTS OF ANY KIND, EITHER EXPRESS OR IMPLIED, WITH
                  REGARD TO INFORMATION ACCESSED FROM OR VIA THE SITE, INCLUDING, WITHOUT
                  LIMITATION, ALL CONTENT AND MATERIALS, FUNCTIONS AND SERVICES PROVIDED ON THE
                  SITE, WHICH ARE PROVIDED WITHOUT WARRANTY OF ANY KIND, INCLUDING, BUT NOT LIMITED
                  TO, ANY WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY, COMPLETENESS, USEFULNESS
                  OR CONTENT OF INFORMATION, UNINTERRUPTED ACCESS AND ANY WARRANTIES OF TITLE,
                  NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. SP DOES NOT
                  WARRANT THAT THE SITE OR ITS FUNCTION OR THE CONTENT AND MATERIALS OR THE SERVICES
                  MADE AVAILABLE THEREBY WILL BE TIMELY, SECURE, UNINTERRUPTED OR ERROR-FREE, OR
                  THAT DEFECTS WILL BE CORRECTED. SP MAKES NO WARRANTY THAT THE SITE WILL MEET
                  USERS’ EXPECTATIONS OR REQUIREMENTS. NO ADVICE, RESULTS OR INFORMATION, OR
                  MATERIALS WHETHER ORAL OR WRITTEN, OBTAINED BY YOU THROUGH THE SITE SHALL CREATE
                  ANY WARRANTY NOT EXPRESSLY MADE HEREIN. IF YOU ARE DISSATISFIED WITH THE SITE,
                  YOUR SOLE REMEDY IS TO DISCONTINUE USING THE SITE. ANY MATERIAL DOWNLOADED OR
                  OTHERWISE OBTAINED THROUGH THE USE OF THE SITE IS DONE AT YOUR OWN DISCRETION AND
                  RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE THAT RESULTS FROM THE
                  DOWNLOAD OF ANY SUCH MATERIAL. THIS LIMITATION OF LIABILITY MAY NOT APPLY TO ALL
                  USERS AND MAY BE LIMITED BY APPLICABLE LAW, AND CERTAIN USERS MAY HAVE ADDITIONAL
                  RIGHTS NOT EXPRESSED HEREIN.
                </p>
              </li>
              <li>
                <p>
                  SP makes no warranties of any kind regarding any sites not controlled by SP to
                  which you may be directed or hyperlinked from this Service. Hyperlinks are
                  included solely for your convenience, and SP makes no representations or
                  warranties with regard to the accuracy, availability, suitability or safety of
                  information provided in such sites not controlled by SP. SP does not endorse,
                  warrant or guarantee any products or services offered or provided by or on behalf
                  of third parties on the Service.
                </p>
              </li>
              <li>
                <p>
                  IN NO EVENT SHALL SP, ITS AFFILIATES OR ANY OF THEIR RESPECTIVE Members, Managers,
                  OFFICERS, EMPLOYEES, AGENTS OR CONTENT OR SERVICE PROVIDERS BE LIABLE TO YOU FOR
                  ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE
                  DAMAGES, LOSSES OR CAUSES OF ACTION (WHETHER IN CONTRACT OR TORT, INCLUDING, BUT
                  NOT LIMITED TO, NEGLIGENCE OR OTHERWISE) ARISING FROM OR IN ANY WAY RELATED TO THE
                  USE OF, OR THE INABILITY TO USE, OR THE PERFORMANCE OF THE SITE OR THE CONTENT AND
                  MATERIALS OR FUNCTIONALITY ON OR ACCESSED THROUGH THE SITE, INCLUDING, WITHOUT
                  LIMITATION, LOSS OF REVENUE, OR ANTICIPATED PROFITS, OR LOST BUSINESS, DATA OR
                  SALES OR ANY OTHER TYPE OF DAMAGE, TANGIBLE OR INTANGIBLE IN NATURE, EVEN IF SP OR
                  ITS REPRESENTATIVE OR SUCH INDIVIDUAL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                  DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THIS LIMITATION OR EXCLUSION OF
                  LIABILITY, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                </p>
              </li>
            </ol>
          </li>
        </ol>

        <p className="legal-justified">&nbsp;</p>
        <p className="legal-justified">&nbsp;</p>
      </section>
    </>
  );
};

export default Terms;
