import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../components/NavBar";
import DarkBlue from "../images/pricing/darkblue.svg";
import LightBlue from "../images/pricing/lightblue.svg";
import Unlinked from "../images/pricing/pieces.svg";
import Linked from "../images/pricing/twopuzz.svg";
import Yellow from "../images/pricing/yellow.svg";
import "../stylesheets/pricing.scss";

const individualList = [
  "Maintain multiple work and non-work profiles to keep your skills organized",
  "Make unlimited feedback requests",
  "Share your summary via link, or in social media to highlight your progress",
  'Control access to the data on your "Work" Profiles',
];

const companiesList = [
  "Employees",
  "Unlimited Profiles",
  "Registration Link",
  "Aggregate reports for work profiles",
  "Manager ratings",
  "Free onboarding and rollout support",
];

const PriceCard = (props) => {
  const {
    puzzle,
    title,
    subtitle,
    price,
    payPeriod,
    stripeColor,
    list,
    btnText,
    centerText,
    buttonUrl,
    enterprise,
  } = props;
  return (
    <div className="price-card">
      <img
        alt=""
        className={`price-card-puzzle ${puzzle === Yellow ? "yellow" : ""}`}
        src={puzzle}
      />
      <div className="plan-title">{title}</div>
      {subtitle && <div className="plan-subtitle">{subtitle} </div>}
      <div className="price">
        <span className={`price-amount ${enterprise ? "enterprise" : ""}`}>{price}</span>
        <span className="price-period"> {payPeriod}</span>
      </div>
      <div className={`price-card-stripe ${stripeColor}`} />
      {enterprise && <span className="price-card-subtitle">Contact us for pricing</span>}
      {centerText && <p className="price-card-center-text">{centerText}</p>}

      {list && (
        <ul className="price-card-list">
          {list.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      )}

      <Link to={buttonUrl} className="price-card-button">
        {btnText}
      </Link>
    </div>
  );
};

const Pricing = () => {
  return (
    <main id="pricing-page">
      <NavBar />
      <div id="pricing-title" className="highlight-text-container">
        <span className="section-header">Pricing Plans</span>
        <div className="canary-highlight" />
      </div>
      <p id="pricing-intro">
        Empowering employees is the key to engagement and retention! Studies show that empowered and
        self-directed employees are far more likely to take the initiative to make your company
        successful. Pricing plans are annual per- employee with a discount scale based on number of
        employees. The license includes unlimited feedback requests and personal profiles. Let us
        help you empower your employees and reduce stress on your managers!
      </p>

      <div className="price-cards">
        <PriceCard
          puzzle={DarkBlue}
          title="For Individuals"
          subtitle="Get the subscriptions through your employer for free"
          price="$3.99"
          payPeriod=" per month"
          stripeColor="dark"
          list={individualList}
          btnText="Buy Now"
          buttonUrl="/"
          centerText="Get feedback easily and anonymously from people you trust"
        />
        <PriceCard
          puzzle={Yellow}
          title="For Employers"
          subtitle={null}
          price="$25"
          payPeriod=" per employee/per year"
          stripeColor="yellow"
          list={companiesList}
          btnText="Buy Now"
          buttonUrl="/"
          centerText={null}
        />
        <PriceCard
          puzzle={LightBlue}
          title="Enterprise"
          subtitle={null}
          price="More than 140 employees"
          payPeriod={""}
          stripeColor="light"
          list={null}
          btnText="Contact Us"
          buttonUrl="/"
          centerText={null}
          enterprise={true}
        />
      </div>
      <img alt="" src={Linked} id="linked" />
      <img alt="" src={Unlinked} id="unlinked" />
    </main>
  );
};

export default Pricing;
