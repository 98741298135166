/* eslint-disable no-restricted-globals */
import { Checkbox, createTheme, CssBaseline, Modal, TextField, ThemeProvider } from "@mui/material";
import { useCallback, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ErrorMessage from "../components/ErrorMessage";
import NavBar from "../components/NavBar";
import Facebook from "../images/Facebook.svg";
import Google from "../images/google.svg";
import Yellow from "../images/yellow.svg";
import { validateEntry } from "../utilities/helpers";
import { registerUser } from "../utilities/services/auth-service";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
          padding: "0.5rem;",
          wordBreak: "break-all;",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: "'Poppins', Helvetica, sans-serif",
          padding: "0 0.5rem",
          "&.Mui-focused": {
            color: "rgb(245, 67, 3)",
          },
        },
      },
    },
  },
});

const Register = (props) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [userInput, setUserInput] = useState({
    password: "",
    confirm: "",
    fullName: "",
    email: "",
    passwordValid: null,
    emailValid: null,
    fullNameValid: null,
  });

  const [error, setError] = useState(null);
  const history = useHistory();

  const handleFormChange = useCallback((e) => {
    const { name, value } = e.target;

    setUserInput((prevState) => ({
      ...prevState,
      [name]: value,
      [`${e.target.name}Valid`]: validateEntry(name, value),
    }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const result = await registerUser(userInput);

      if (!result.success) {
        setError(result.message);
      } else {
        history.push({
          pathname: "/welcome",
          state: { name: result.userGivenName },
        });
      }
    },
    [userInput, history]
  );

  const passwordIsPassword = userInput.password.toLowerCase() === "password";

  return (
    <>
      <NavBar />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <section id="auth">
          <Modal open={!!error} onClose={() => setError(null)}>
            <div>
              <ErrorMessage message={error} color="black" />
            </div>
          </Modal>
          <div className="auth-img-container">
            <img alt="" src={Yellow} />
          </div>
          <div className="highlight-text-container align-self-center">
            <span className="section-header">Sign Up</span>
            <div className="orange-highlight" />
          </div>
          <div className="auth-subtitle align-self-center">
            Let's create your Skillprofile account.
          </div>
          <div className="third-party-auth-btn orange align-self-center">
            <div className="third-party-logo">
              <img alt="Google sign-in" src={Google} />
            </div>
            <div className="third-party-auth-label">Continue with Google</div>
          </div>
          <div className="third-party-auth-btn blue align-self-center">
            <div className="third-party-logo">
              <img alt="Facebook sign-in" src={Facebook} />
            </div>
            <div className="third-party-auth-label">Continue with Facebook</div>
          </div>
          <div className="orline-wrapper">
            <div className="orline" />
            <div className="roundy">or</div>
          </div>
          <div className="form-container auth-form-container">
            <form className="auth-form" onSubmit={handleSubmit}>
              <TextField
                required
                id="fullName"
                name="fullName"
                label="Full name"
                variant="standard"
                onChange={(e, text) => handleFormChange(e, text)}
              />
              <TextField
                required
                id="email"
                name="email"
                label="Email"
                variant="standard"
                onChange={(e, text) => handleFormChange(e, text)}
                error={userInput.emailValid === false}
                helperText={userInput.emailValid === false ? "Email Invalid" : ""}
              />
              <TextField
                required
                id="password"
                name="password"
                type="password"
                label="Password"
                variant="standard"
                onChange={(e, text) => handleFormChange(e, text)}
                error={passwordIsPassword || userInput.passwordValid === false}
                helperText={
                  !passwordIsPassword &&
                  (userInput.password.length === 0 || userInput.passwordValid)
                    ? ""
                    : passwordIsPassword
                      ? `The password cannot be "password".`
                      : "Password invalid"
                }
              />
              <TextField
                required
                id="confirm"
                name="confirm"
                type="password"
                label="Confirm Password"
                variant="standard"
                onChange={(e, text) => handleFormChange(e, text)}
                error={userInput.password.length > 0 && userInput.password !== userInput.confirm}
                helperText={
                  userInput.password.length > 0 && userInput.password !== userInput.confirm
                    ? "Passwords don't match"
                    : ""
                }
              />
              <div className="accept-terms-container">
                <Checkbox
                  required
                  checked={termsAccepted}
                  onChange={() => setTermsAccepted(!termsAccepted)}
                />
                <span className="accept-terms">
                  I accept the{" "}
                  <Link to="/terms" target="_blank">
                    {"Terms & Conditions"}
                  </Link>{" "}
                  <br />
                  and{" "}
                  <Link to="/privacy" target="/blank">
                    Privacy Policy
                  </Link>
                </span>
              </div>
              <input
                className={`button-long submit ${passwordIsPassword ? "angry" : ""}`}
                type="submit"
                value="Sign Up"
                label="Sign Up"
                disabled={
                  userInput.passwordValid === false ||
                  userInput.emailValid === false ||
                  !termsAccepted ||
                  userInput.fullNameValid === false ||
                  passwordIsPassword
                }
              />
            </form>
          </div>
        </section>
      </ThemeProvider>
    </>
  );
};

export default Register;
