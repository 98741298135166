import Logo from "../images/logo_feedback.svg";

const FeedbackPageLogo = () => {
  return (
    <section className="logo-container">
      <img alt="SkillProfile logo" src={Logo} />
    </section>
  );
};

export default FeedbackPageLogo;
