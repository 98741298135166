import moment from 'moment';

const UserLineChart = (props) => {
  const { users } = props;
  //const startDate = moment().subtract(12, 'months').format('MMM YYYY');
  //const endDate = moment().format('MMM YYYY');
  let months = [];
  for (let i = 0; i < 13; i++) {
    months.push(moment().subtract(i, 'months').format('MMM YYYY'));
  }

  const aggregated = {};

  //first create our base Object.
  for (let i = 0; i < months.length; i++) {
    aggregated[months[i]] = 0;
  }

  //after which we can iterate over all users and check their date against the month and year
  for (let i = 0; i < users.length; i++) {
    let currentDate = users[i].created;
    //conversion happens below this line
    console.log(currentDate);
  }
  const data = months.map((month) => {
    return {
      name: month,
      uv: 10,
      pv: 24,
      amt: 24,
    };
  });
  console.log(data);

  return <div className="column center">{'Chart gonna go here'}</div>;
};

export default UserLineChart;
