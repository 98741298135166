import { Link } from "react-router-dom";
import Attitudes from "../images/attitues.svg";
import Competencies from "../images/competencies.svg";
import Knowledge from "../images/knowledge.svg";
import Skills from "../images/skiils.svg";

const ListItem = ({ image, title, text }) => {
  return (
    <div className="column list-item quarter center">
      <div>
        <img alt="" src={image} />
      </div>
      <span className="paragraph-header">{title}</span>
      <p className="paragraph-quarter">{text}</p>
    </div>
  );
};

const WhatIs = ({ element }) => {
  return (
    <section id="whatis" className="column home-page-column-container" ref={element}>
      <div className="column center">
        <span className="subtitle yellow">It's Uniquely Yours</span>
        <span className="highlight-text-container">
          <span className="subsection-header">What Is SkillProfile?</span>
          <div className="orange-highlight short" />
        </span>
        <p className="paragraph-big center">
          A skill profile is the set of Knowledge, Skills, Attitudes and Competencies that make you
          unique. Each set will be specific to the individual based on what is important to them,
          their experiences, interests and purposes for using a skill profile.
        </p>
      </div>
      <div className="row main-container top-aligned">
        <ListItem
          image={Knowledge}
          title="Knowledge"
          text="A knowledge type of attribute is going to be tied to something you can learn. It might
            be math, or a particular game like a MOBA, or how to fix a car, or how to paint a
            landscape. This is information and knowledge that you want someone to give you feedback
            on."
        />
        <ListItem
          image={Skills}
          title="Skills"
          text="A skill type of attribute is something you have learned to do or practice. Usually these
            are talents or they might be tangible work products, like writing a piece of code or
            building a table. They can be something you are trying to practice like pulling creeps
            in DOTA or getting a headshot in an FPS game."
        />
        <ListItem
          image={Attitudes}
          title="Attitudes"
          text="Attitude attributes are more the way you go about solving a problem or how well you
            collaborate with teammates. Attitudes might be how frequently you speak up or how well
            you maintain a positive outlook in the face of frustration."
        />
        <ListItem
          image={Competencies}
          title="Competencies"
          text="A competency attribute is often a more complex set of the above items, it is the
            combination that usually result in some sort of work product. In other words, it covers
            the capability to apply or use a set of related knowledge, skills, and abilities
            required to successfully perform tasks in a defined work setting. An example of this
            might be writing a specification or helping customers."
        />
      </div>
      <div className="row center get-the-app">
        <Link to="/welcome" target="_blank" className="button-long">
          Get The App
        </Link>
      </div>
    </section>
  );
};

export default WhatIs;
