import "../stylesheets/results-marketing.scss";
import attributeData from "../utilities/json/results.json";
import AttributeAttitudes from "./AttributeAttitudes.jsx";
import AttributeCompetencies from "./AttributeCompetencies.jsx";
import AttributeKnowledge from "./AttributeKnowledge.jsx";
import AttributeSkills from "./AttributeSkills.jsx";

const ResultsAttributes = () => {
  return (
    <section id="results-attributes">
      <AttributeSkills attributeData={attributeData} />
      <AttributeKnowledge attributeData={attributeData} />
      <AttributeCompetencies attributeData={attributeData} />
      <AttributeAttitudes attributeData={attributeData} />
    </section>
  );
};

export default ResultsAttributes;
