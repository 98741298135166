import "../stylesheets/thank-you.scss";

const Appreciation = () => {
  return (
    <main id="thankyou">
      <div className="orange-rect">
        <div className="section-header dark">Thank You!</div>
      </div>
      <p className="paragraph-big center">Your feedback was submitted successfully!</p>
      <a className="button-long" href="/">
        Home
      </a>
    </main>
  );
};

export default Appreciation;
