import OneMark from "../images/1.svg";
import TwoMark from "../images/2.svg";
import ThreeMark from "../images/3.svg";
import PuzzleTwo from "../images/puzzle-2.svg";
import ScreenThree from "../images/Screen_3_actual_png.png";

const ListItem = ({ image, text }) => {
  return (
    <span className="flex-row list-item">
      <img alt="Item one" src={image} />
      <span className="custom-list-item">{text}</span>
    </span>
  );
};

const YourProfiles = ({ element }) => {
  return (
    <section id="your-profiles" className="row main-container">
      <div className="column">
        <div className="puzzle-piece-wrapper">
          <img className="responsive_image" alt="" src={PuzzleTwo} />
        </div>
        <span className="subtitle">Create profiles for every facet of life</span>
        <span className="highlight-text-container">
          <span className="subsection-header">Your Profiles</span>
          <div className="blue-highlight" />
        </span>
        <p className="paragraph-big">
          Manage multiple profiles to organize your knowledge, skills, attitudes and competency
          attributes across your life. Create different profiles for work, play, school or other
          activities and interests. Send feedback requests to different people who know you in
          different capacities and monitor your progress and you try out new behaviors and build new
          skills.
        </p>
        <div className="flex-column">
          <ListItem
            image={OneMark}
            text="Organize your various attributes into separate profiles for different aspects of life"
          />
          <ListItem
            image={TwoMark}
            text="Use SkillProfile to manage these attributes and send new requests periodically get
              updated ratings"
          />
          <ListItem
            image={ThreeMark}
            text="Add new profiles as you explore new activities and measure your own personal progress
              toward mastery"
          />
        </div>
      </div>
      <div className="column">
        <div className="bottom-image">
          <img src={ScreenThree} alt="Screen Three" />
        </div>
      </div>
    </section>
  );
};

export default YourProfiles;
