import { useMemo } from "react";
import Attitudes from "../images/attitues.svg";
import Competencies from "../images/competencies.svg";
import Knowledge from "../images/knowledge.svg";
import Skills from "../images/skiils.svg";
import getCustomSlider from "../utilities/styled-components/get-custom-slider";

const ProfileCard = (props) => {
  const icons = {
    knowledge: Knowledge,
    skills: Skills,
    attitudes: Attitudes,
    competencies: Competencies,
  };

  const { attribute, title, handleChange, username, value, index, shouldDisplayAsResults } = props;

  const CustomSlider = useMemo(
    () => getCustomSlider(shouldDisplayAsResults, title),
    [shouldDisplayAsResults, title]
  );

  const headerText = `${username}'s ${title.charAt(0).toUpperCase()}${title.slice(1)}`;
  const attributeLabelText = attribute.name || attribute.AttributesName;
  const min = shouldDisplayAsResults ? 0 : 1;
  const valueLabelDisplay = shouldDisplayAsResults ? "off" : "on";
  const isDisabled = shouldDisplayAsResults ? true : false;

  return (
    <div className="profile-card">
      <div className="profile-card-top">
        <div className="profile-card-icon">
          <img alt={title} src={icons[title]} />
        </div>
        <article className="profile-card-header">{headerText}</article>
      </div>
      <article className={`attribute-label ${title}`}>{attributeLabelText}</article>
      <article className="profile-card-slider-container">
        <CustomSlider
          min={min}
          max={5}
          step={1}
          marks={false}
          valueLabelDisplay={valueLabelDisplay}
          index={index}
          value={value}
          disabled={isDisabled}
          onChange={(e, newValue) => handleChange(e, newValue, index)}
        />
      </article>
      {shouldDisplayAsResults && (
        <article className="profile-card-average">
          <strong>Average:</strong> {attribute.AverageScore}
        </article>
      )}
    </div>
  );
};

export default ProfileCard;
