import CloseIcon from "@mui/icons-material/Close";
import copy from "../images/results/Copy.svg";
import facebook from "../images/results/Facebook.svg";
import linkedin from "../images/results/Linked in.svg";
import messenger from "../images/results/Messenger.svg";
import pinterest from "../images/results/Pinterest.svg";
import puzzle1 from "../images/results/Puzzle_1.svg";
import whatsapp from "../images/results/Whatsapp.svg";
import x from "../images/results/X.svg";
import "../stylesheets/results-marketing.scss";

const ResultsShare = () => {
  function handleShareButton(e) {
    e.preventDefault();
  }
  return (
    <section id="results-share">
      <div className="highlight-text-container">
        <div className="subsection-header">Share to:</div>
        <div className="blue-highlight" />
      </div>
      <div className="share-row-container">
        <form>
          <input
            className="text-field"
            type="readOnly"
            defaultValue="skill-profile.com/share_profile76712391"
          />
        </form>{" "}
        <button className="button-short" onClick={handleShareButton}>
          <img className="copy-icon" alt="copy" src={copy} />
          Copy Share Link
        </button>
        <div id="or-text">or</div>
        <div>
          <img className="social-icon" alt="linkedin" src={linkedin} />
          <img className="social-icon" alt="facebook" src={facebook} />
          <img className="social-icon" alt="x" src={x} />
          <img className="social-icon" alt="whatsapp" src={whatsapp} />
          <img className="social-icon" alt="messenger" src={messenger} />
          <img className="social-icon" alt="pinterest" src={pinterest} />
          <img className="right-puzzle-piece" alt="" src={puzzle1} />
        </div>
      </div>
      <div className="results-private-container">
        <label className="switch">
          <div className="switch-track">
            <input type="checkbox" />
            <CloseIcon id="slider" />
          </div>
        </label>
        <span>Keep results private</span>
      </div>
    </section>
  );
};

export default ResultsShare;
