// import React, { useState } from "react";

const Login = () => {
  // TODO: implement the form
  // const [data, setData] = useState({ email: "", password: "", passwordConf: "" });

  return (
    <div className="column center">
      <div className="row">
        <span>SkillProfile</span>
      </div>
      <div className="form-container">
        <form className="form">
          <input></input>
        </form>
      </div>
    </div>
  );
};

export default Login;
