/**
 * The purpose is to simply close when "Use another Email" is clicked, or redirect to Login when "Log in instead"
 * @param {*} props
 */
const ErrorMessage = (props) => {
  const { message, color } = props;
  return (
    <div className="error-box">
      <span className={`error-message ${color || ''}`}>{message}</span>
    </div>
  );
};

export default ErrorMessage;
