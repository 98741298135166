export const getAverage = (array) => {
    let sum = 0;
    for (let i = 0; i < array.length; i++) {
        sum += Math.floor(array[i], 5);
    }
    let avg = sum / array.length;
    return avg;
}

export const isSequential = (string) => {
    for (let index = 0; index < string.length; index++) {
        if (string[index] > string[index + 1]) {
            return false
        }
    }
    return true
}

export const findMatch = (queryString, stringWeBeChecking) => {
    const queries = queryString.split(' ');
    for (let i = 0; i < queries.length; i++) {
        if (stringWeBeChecking.match(queries[i])) {
            return true;
        }
    }
    return false;
}

export const hasTooManyRepeats = (string) => {
    let repeats = 0;
    for (let index = 0; index < string.length; index++) {
        if (string[index] === string[index + 1]) {
            repeats += 1;
            if (repeats === 3) {
                return true;
            }
        } else {
            repeats = 0;
        }

    }
    return false;
}


export const validateEntry = (field, value) => {
    if (value === "") return null;
    switch (field) {
        case "email": {
            return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);  // eslint-disable-line
        }
        case "username": {
            return /^[a-zA-Z\d-_]+$/.test(value); // eslint-disable-line 
        }
        case "password": {
            return (!hasTooManyRepeats(value) && !isSequential(value) && value.length >= 6)
        }
        default: {
            return true;
        }
    }
}
