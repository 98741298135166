import { createTheme } from "@mui/material/styles";

const navbarTheme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          padding: "0.5rem 0 0.5rem 0",
          borderTop: "1px solid #eee",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0 0 0.5rem 0",
          "&.Mui-expanded": {
            minHeight: 0,
          },
        },
        content: {
          margin: 0,
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: "0.5rem 0",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: 0,
          "&.Mui-disabled": {
            opacity: 1,
          },
        },
      },
    },
  },
});

export default navbarTheme;
