import $ from "jquery";
import { Link } from "react-router-dom";
import NavBar from "../components/NavBar";
import "../stylesheets/legal.scss";

const Privacy = () => {
  const scrollToCookies = () => {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $("#cookies").offset().top - 100,
      },
      1000
    );
  };
  return (
    <>
      <NavBar />
      <section id="privacy">
        <div className="highlight-text-container align-self-center">
          <span className="section-header center">Privacy Policy</span>
          <div className="orange-highlight" />
        </div>
        <h2 className="subsection-header">Effective Date: June 25, 2021</h2>
        <p className="legal-justified">
          This Privacy Policy describes how SkillProfile, Inc., and our brands and subsidiaries
          (“SP”, “we”, “us”, or “our”) collect, use and share information about you. This policy
          applies to information we collect when you use our websites (www.skillprofile.com), apps,
          shop in our online stores or otherwise interact with us as described below (collectively,
          the “Site”). We may change this Privacy Policy from time to time. If we make changes, we
          will notify you by revising the date at the top of this policy, and in some cases, we may
          provide you with additional notice (such as adding a statement to the homepages of our
          websites or sending you an email notification). We encourage you to review the Privacy
          Policy whenever you interact with us to stay informed about our information practices and
          the ways you can help protect your privacy. Capitalized terms not defined herein shall
          have the meanings ascribed thereto in our <Link to="/terms">Terms of Service.</Link>
        </p>
        <p className="legal-header">Acceptance Of These Terms</p>
        <p className="legal-justified">
          By using the Site, you signify your acceptance of this policy and any changes to this
          policy. If you do not agree to this policy, please do not use our Site. Your continued use
          of the Site following the posting of changes to this policy will be deemed your acceptance
          of those changes.
        </p>
        <p className="legal-header">Collection Of Information</p>
        <p className="legal-justified">
          <span class="underlined">Information You Provide to Us.</span> We collect information you
          provide directly to us. As used in this Privacy Policy, “Personal Information” has the
          meaning provided in the EU General Data Protection Regulation of 2018 (“GDPR”), and
          includes any information we collect when you use our websites, apps, purchase our
          services, call us on the phone, create an online account, sign up to receive our emails,
          participate in a sweepstakes, contest, promotion or survey, communicate with us via third
          party social media sites, request customer support, apply for a job or otherwise
          communicate with us.{" "}
        </p>
        <p className="legal-justified">
          In some cases, we may also collect information you provide about others, such as when you
          purchase a gift card for someone and request that we deliver it electronically, create and
          share a “wish list” or decide to purchase and ship products to someone. We will use this
          information to fulfill your requests and may also use that information to send marketing
          communications to your contact, unless he or she has previously opted out of marketing
          communications from SP.
        </p>
        <p className="legal-justified">
          <span class="underlined">Information We Collect Automatically.</span> We automatically
          collect certain information about you when you access or use our Site or transact business
          with us, including: (i) information about your use of our websites, such as the type of
          browser you use, access times, pages viewed, your IP address and the referring link
          through which you accessed our websites; (ii) information about the transaction, such as
          product details and the date and location of the purchase/return; and (iii) we may use
          cookies, web beacons, also known as “tracking pixels”) and other tracking technologies to
          collect information about you when you interact with our websites or emails, including
          information about your browsing and purchasing behavior. Please refer to our{" "}
          <span className="intrapage-link" onClick={scrollToCookies}>
            Cookie Policy
          </span>{" "}
          for our current practices regarding cookies. We may combine this information with other
          information we collect about you and use it for various purposes, such as improving our
          websites and your online experience, understanding which areas and features of our sites
          are popular, counting visits, understanding campaign effectiveness, tailoring our
          communications with you, determining whether an email has been opened and links within the
          email have been clicked and for other internal business purposes.
        </p>
        <p className="legal-justified">
          <span class="underlined">Retention and Transfer of Personal Information.</span> We retain
          Personal Information that you provide us as long as we consider it potentially useful in
          contacting you about our products and services, or as needed to comply with our legal
          obligations, resolve disputes and enforce our agreements. We will delete this information
          from the servers at an earlier date if you so request by unsubscribing, opting-out, or
          emailing us at info@skillprofile.com. Personal Information is transferred and stored to
          secure servers in the United States.
        </p>
        <p className="legal-header">Use Of Information </p>
        <p className="legal-justified">
          We may use information about you for various purposes, including to: (i) facilitate and
          improve your online shopping experience; (ii) provide the products and services you
          request, process transactions and send you related information, including confirmations
          and receipts; (iii) respond to your comments, questions and requests and provide customer
          service; (iv) communicate with you about products, services, offers, promotions, rewards
          and events and provide news and information we think will be of interest to you; (v)
          manage your online account(s) and send you technical notices, updates, security alerts and
          support and administrative messages; (vi) personalize your online experience and provide
          advertisements, content or features that match your profile and interests; (vii) monitor
          and analyze trends, usage and activities; (viii) process and deliver contest, promotion
          and sweepstakes entries and rewards; (ix) link or combine with information we get from
          others to help understand your needs and provide you with better service; and (x) carry
          out any other purpose for which the information was collected. We are based in the United
          States and the information we collect is governed by U.S. law. By accessing or using our
          Site or otherwise providing information to us, you consent to the processing and transfer
          of information in and to the U.S. and other countries.
        </p>
        <p className="legal-header">Sharing of Information </p>
        <p className="legal-justified">
          We may share information about you as follows: (i) with vendors, consultants and other
          service providers who need access to such information to carry out work on our behalf;
          (ii) with our strategic partners and for purposes of providing services to you; (iii) in
          response to a request for information if we believe disclosure is in accordance with any
          applicable law, regulation or legal process or as otherwise required by any applicable
          law, rule or regulation; (iv) if we believe your actions are inconsistent with our user
          agreements or policies, or to protect the rights, property and safety of us or any third
          party; (v) in connection with, or during negotiations of, any merger, sale of company
          assets, financing or transfer of all or a portion of our business to another company; or
          (vi) with your consent or at your direction. We may also share aggregated or de-identified
          information, which cannot reasonably be used to identify you.
        </p>
        <p className="legal-header">Your Information Choices and Control</p>
        <p className="legal-justified">
          SP respects your need to access and control your Personal Information and Content, so we
          give you certain choices that you may exercise, including:
        </p>
        <ul>
          <li>
            <p>You may correct, update, and delete your account.</p>
          </li>
          <li>
            <p>You may change your choices for subscriptions, newsletters, and alerts.</p>
          </li>
          <li>
            <p>
              You may choose whether to receive from us offers and promotions for our products and
              services, or products and services that we think may be of interest to you.
            </p>
          </li>
          <li>
            <p>
              You may request access to the Personal Information we hold about you and that we amend
              or delete it, including your Content. Please note that we may be required to keep such
              information and not delete it (or to keep this information for a certain time, in
              which case we will comply with your deletion request only after we have fulfilled such
              requirements). When we delete any information, it will be deleted from the active
              database, but residual copies may remain in our Service’s back-up systems due to the
              nature of those systems’ operations.
            </p>
          </li>
          <li>
            <p>We may also retain your information for fraud or similar purposes.</p>
          </li>
          <li>
            <p>
              You can exercise these controls and choose to opt in or out of services and
              communications by visiting your account preferences, contacting our customer service
              at info@skillprofile.com, or following the instructions directly provided in the
              communications we send you.
            </p>
          </li>
        </ul>
        <p className="legal-header">Advertising and Analytics </p>
        <p className="legal-justified">
          We engage third parties to serve advertisements on our behalf across the Internet and to
          provide analytics services. These entities may use cookies, web beacons and other
          technologies to collect information about your use of our websites, such as your IP
          address, web browser, pages viewed, time spent on pages, links clicked and conversion
          information. This information may be used by us and others to, among other things, analyze
          and track data, determine the popularity of certain content, deliver advertising and
          content targeted to your interests on our websites and other websites and better
          understand your online or offline activity. We may also work with third parties to serve
          ads to you as part of a customized campaign on other websites or platforms.{" "}
        </p>
        <p className="legal-header" id="cookies">
          Cookies
        </p>
        <p className="legal-justified">
          We use functional and analytical cookies to provide for the best user experience. We, as
          well as third parties, also use tracking cookies on our websites to show you customized
          advertisements and offers on our websites, as well as on third party websites and apps.
          Most web browsers are set to accept cookies by default. If you prefer, you can usually set
          your browser to remove or reject cookies, but note that doing so does not necessarily
          affect third party flash cookies used in connection with our websites. Please note that if
          you choose to remove or reject cookies, this could affect the availability and
          functionality of our websites. In addition, many web browsers support Do Not Track
          technology. If you enable Do Not Track, Note, however, third-party companies may not
          recognize browser “Do Not Track” signals. If you would like to opt out of the collection
          and use of tracking data for ad targeting, please visit 
          <a href="www.aboutads.info/choices/">www.aboutads.info/choices/</a>.
        </p>
        <p className="legal-header">Links To 3rd Party Websites</p>
        <p className="legal-justified">
          Our Site may contain links to other websites. Our Privacy Policy does not apply to the
          practices of other websites and SP is not responsible for the actions and privacy policies
          of third parties. We encourage you to be aware of when you leave our Site and to read the
          privacy policies of each website that you visit.
        </p>
        <p className="legal-header">Security</p>
        <p className="legal-justified">
          We take reasonable measures to help protect information about you from loss, theft, misuse
          and unauthorized access, disclosure, alteration and destruction. We use organizational,
          procedural, and technical safeguards to secure data in our possession, consistent with the
          sensitivity level of such data. When sensitive information (such as a credit card data) is
          collected on our Site it is protected through the use of encryption, such as the Secure
          Socket Layer (SSL) protocol and may be processed by third parties using similar levels of
          protection. Regardless of the precautions we take, no transmission of data over the
          internet is guaranteed to be completely secure. It may be possible for third parties not
          under our control to intercept or access transmissions or private communications
          unlawfully. While we strive to protect Personal Information, we cannot ensure or warrant
          the security of any information you transmit to us.
        </p>
        <p className="legal-header">Site Users Outside the United States</p>
        <p className="legal-justified">
          By using the Service and providing us with data as non-U.S. user, you acknowledge and
          agree that your Personal Information may be processed for the purposes identified in this
          Privacy Policy. We are based in the United States and the information we collect is
          governed by U.S. law. By accessing or using our websites or otherwise providing
          information to us, you consent to the processing and transfer of information in and to the
          U.S. and other countries.
        </p>
        <p>You may have the following data protection rights: </p>
        <ul>
          <li>
            <p>
              To access, correct, update or request deletion of Personal Information. We take
              reasonable steps to ensure that the data we collect is reliable for its intended use,
              accurate, complete and up to date. As a registered user, you can manage many of your
              individual account and profile settings within your account provided through the
              Service, or you may contact us directly by emailing us at info@skillprofile.com. We
              will consider your request in accordance with applicable laws.
            </p>
          </li>
          <li>
            <p>
              In addition, individuals who are residents of the European Economic Area (“EEA”)
              can object to processing of their Personal Information, ask to restrict processing of
              their Personal Information or request portability of their Personal Information. You
              can exercise these rights by contacting us at info@skillprofile.com.
            </p>
          </li>
          <li>
            <p>
              Similarly, if Personal Information is collected or processed on the basis of consent
              you can withdraw consent at any time. Withdrawing your consent will not affect the
              lawfulness of any processing we conducted prior to your withdrawal, nor will it affect
              processing of your Personal Information conducted in reliance on lawful processing
              grounds other than consent.
            </p>
          </li>
          <li>
            <p>
              EEA residents have the right to complain to a data protection authority about the
              collection and use of Personal Information. For more information, please contact your
              local data protection authority. Contact details for data protection authorities in
              the EEA are available 
              <a href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">
                here.
              </a>
            </p>
          </li>
        </ul>
        <p className="legal-justified">
          We respond to all requests we receive from individuals wishing to exercise their data
          protection rights in accordance with applicable data protection law. We may ask you to
          verify your identity in order to help us respond efficiently to your request.
        </p>
        <p className="legal-header">Children</p>
        <p className="legal-justified">
          We do not knowingly collect any Personal Information from children under the age of 13. If
          you believe we might have any information from or about a child under 13, please contact
          us at info@skillprofile.com.
        </p>
        <p className="legal-header">California Privacy Rights</p>
        <p className="legal-justified">
          California law permits residents of California pursuant to{" "}
          <a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=1798.83.&lawCode=CIV">
            California Civil Code Section 1798.83
          </a>{" "}
          to request certain details about how their information is shared with third parties for
          direct marketing purposes. To make such a request, please send an email to
          info@skillprofile.com or write to us at the address in the Contact Section below. Under
          the law, a business must either provide this information or permit California residents to
          opt in to, or opt out of, this type of sharing. SP permits California residents to opt out
          of having their information shared with third parties for direct marketing purposes. To
          opt out, please click “unsubscribe” on any emails from us or email us at
          info@skillprofile.com requesting to be removed.{" "}
        </p>
        <p className="legal-header">Contact Us</p>
        <p className="legal-justified">
          If you have any questions about this Privacy Policy, the practices of this Site, or your
          dealings with this Site, please contact us at: (i) by mail at Skill Profile, 3031 Tisch
          Way, San Jose, CA; or (ii) by email at info@skillprofile.com. Please allow up to 30 days
          for a response and in some cases we may request additional info to verify your identity.
        </p>
        <p className="legal-justified">&nbsp;</p>
        <p className="legal-justified">&nbsp;</p>
      </section>
    </>
  );
};

export default Privacy;
