import { firebase } from "@firebase/app";
import "firebase/auth";
import "firebase/firestore";

import constants from "./constants";

const firebaseConfig = constants.FIREBASE_CONFIG;

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const app = firebase.app();
const auth = app.auth();
const db = app.firestore();

export { auth, app, constants, db, firebase };
