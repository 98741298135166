import NavBar from "../components/NavBar";
import ResultsAttributes from "../components/ResultsAttributes.jsx";
import ResultsPageHeader from "../components/ResultsPageHeader.jsx";
import ResultsProfileHeader from "../components/ResultsProfileHeader.jsx";
import ResultsShare from "../components/ResultsShare.jsx";
import "../stylesheets/results-marketing.scss";

const ResultsMarketing = () => {
  return (
    <>
      <NavBar />
      <main id="results-marketing">
        <ResultsPageHeader />
        <ResultsProfileHeader />
        <ResultsAttributes />
        <ResultsShare />
        {/* <Footer /> */}
      </main>
    </>
  );
};

export default ResultsMarketing;
