import { db, firebase } from "../firebase/firebase-init";

const getRequest = async (requestId) => {
  try {
    const doc = await db.collection("QueuedFeedbackRequests").doc(requestId).get();

    return doc.data();
  } catch (error) {
    console.error(
      `feedback-service couldn't load from QueuedFeedbackRequests where requestUid=${requestId}': `,
      error
    );
    throw error;
  }
};

const _createFeedbackResponse = (feedbackRequest, sender, showFullComment, comment) => {
  const timestamp = firebase.firestore.Timestamp.fromDate(new Date());
  const feedbackUserUid = feedbackRequest.FeedbackUserUid || "UnregisteredUser";
  const feedbackResponse = {
    Created: timestamp,
    FeedbackRequestId: feedbackRequest.FeedbackRequestUid,
    RequestSenderUid: sender.uid,
    ProfileId: feedbackRequest.ProfileUid,
    FeedbackUserEmail: feedbackRequest.RecipientEmail,
    FeedbackUserUid: feedbackUserUid,
    IsVisible: showFullComment,
    Comment: comment,
  };

  return feedbackResponse;
};

const _updateAttributeRatings = async (profileAttributes, ratings, refId) => {
  const updates = profileAttributes.map(async (attribute, index) => {
    try {
      const rating = ratings[index].rating.toFixed(2);
      const attributeRating = {
        AttributeId: attribute.id,
        Rating: rating,
        ProfileAttributeId: attribute.paid,
      };

      await db
        .collection("FeedbackResponses")
        .doc(refId)
        .collection("AttributesRatings")
        .add(attributeRating);
    } catch (error) {
      console.error(
        `feedback-service couldn't add an attribute rating for attribute ID ${attribute.id}:`,
        error
      );
    }
  });

  await Promise.all(updates);

  return updates;
};

const submitFeedback = async (
  feedbackRequest,
  sender,
  profileAttributes,
  ratings,
  showFullComment,
  comment
) => {
  try {
    const feedbackResponse = _createFeedbackResponse(
      feedbackRequest,
      sender,
      showFullComment,
      comment
    );

    const responseRef = await db.collection("FeedbackResponses").add(feedbackResponse);
    const attributeRatingUpdates = await _updateAttributeRatings(
      profileAttributes,
      ratings,
      responseRef.id
    );

    await Promise.all(attributeRatingUpdates);

    return {
      success: true,
      feedbackResponseId: responseRef.id,
      message: "Feedback submitted successfully",
    };
  } catch (error) {
    console.error("Error submitting feedback:", error);

    return {
      success: false,
      error: error.message,
      message: "Failed to submit feedback",
    };
  }
};

export { getRequest, submitFeedback };
