import AboutSkillProfile from "../components/AboutSkillProfile";
import GetStarted from "../components/GetStarted";
import NavBar from "../components/NavBar";
import Top from "../components/Top";
import WhatIs from "../components/WhatIs";
import WhoUse from "../components/WhoUse";
import YourProfiles from "../components/YourProfiles";
import YourResults from "../components/YourResults";
import "../stylesheets/home-page.scss";

const Home = () => {
  return (
    <main id="home-page" className="flex-column flex-wrap">
      <NavBar />
      <div className="home-page-section-container">
        <Top />
        <GetStarted />
        <YourResults />
        <YourProfiles />
        <WhatIs />
        <AboutSkillProfile />
        <WhoUse />
      </div>
    </main>
  );
};

export default Home;
