import { useMediaQuery } from "@mui/system";
import React from "react";
import NavBar from "../components/NavBar";
import Gray from "../images/employers/gray.svg";
import Green from "../images/employers/GreenWithHome.png";
import BlueCheck from "../images/employers/green_tick.svg";
import Illustration from "../images/employers/Illustration.svg";
import Mockup from "../images/employers/mochup2.svg";
import PuzzTwo from "../images/employers/Puzzle_2.svg";
import PuzzThree from "../images/employers/Puzzle_3.svg";
import PuzzFour from "../images/employers/Puzzle_4.svg";
import BigRed from "../images/employers/red.svg";
import OrgReport from "../images/employers/reports.svg";
import OrangeCheck from "../images/employers/tick.svg";
import WorkProfile from "../images/employers/workprofilereport.svg";

const FactContainer = ({ image, text }) => {
  return (
    <div className="fact-container">
      <img src={image} alt="" />
      <span className="mini-fact">{text}</span>
    </div>
  );
};

const ForEmployers = () => {
  const big = useMediaQuery("(min-width:768px)");
  return (
    <main id="for-employers">
      <NavBar />
      <div id="benefits">
        <span className="section-header"> Offer SkillProfile as a Benefit </span>
        <span className="highlight-text-container">
          <span className="section-header">For Your Employees</span>
          <div className="orange-highlight" />
        </span>
      </div>
      <div className={big ? "facts-row" : "facts-column"}>
        <FactContainer
          image={OrangeCheck}
          text="Empower them to get their own feedback and ratings, reducing costs and time for managers."
        />
        <FactContainer
          image={OrangeCheck}
          text="See skills/overall ratings for Work type profiles, understanding your talent pool."
        />
        <FactContainer
          image={OrangeCheck}
          text="Plan employee development based on independently rated skills."
        />
        <FactContainer
          image={OrangeCheck}
          text="Bring 360-type feedback to all your employees, not just your executives."
        />
      </div>
      <div className={big ? "row" : "column"}>
        <a
          className="button-long"
          rel="noreferrer"
          target="_blank"
          href="https://calendly.com/kristin-skillprofile/skillprofile-for-employers"
        >
          Book A Demo
        </a>
      </div>
      <div className="container-for-bigred">
        <div className={big ? "row top-aligned" : "column center"}>
          <img className="guy" src={Illustration} alt="" />
          <div className="facts-col">
            <span className="highlight-text-container">
              <span className="section-header">Value Proposition</span>
              <div className="blue-highlight bottom" />
            </span>

            <FactContainer
              image={BlueCheck}
              text="Offer a benefit to Employees to get ratings and feedback on their knowledge, skills, attitudes and competencies from people they trust."
            />
            <FactContainer
              image={BlueCheck}
              text="Reduce the load on managers for providing ongoing feedback, while providing them visibility into the growth of their teams."
            />
            <FactContainer
              image={BlueCheck}
              text="Gain a tool that helps you understand the skills in your organization and identify areas for growth and training."
            />
            <FactContainer
              image={BlueCheck}
              text="Get the benefit of 360 type feedback without the overhead of a costly Talent Mgt tool or the time commitment on managers and staff."
            />
          </div>
        </div>
        <img className="bigred" src={BigRed} alt="" />
      </div>

      <div className="column center" id="employer-experience">
        <span className="highlight-text-container">
          <span className="section-header">The Employer Experience</span>
          <div className="salmon-highlight" />
        </span>
        <p className="center-text">
          Embrace a skill based approach to Talent Management by offering SkillProfile to your
          employees to manage their Knowledge, Skills, Attitudes and Competencies and unlock the
          unknown pockets of talent in your organization.
        </p>
        <img className="gray" src={Gray} alt="" />
      </div>

      <div className={big ? "row" : "column"}>
        <div className="column">
          <span className="employers-header">Manage/Invite Employees</span>
          <p>
            Manage employee seats and easily invite them to use the mobile application or web based
            application to request feedback for their skills.
          </p>
        </div>
        <img className="mockup" src={Mockup} alt="A mockup of the employer experience page" />
      </div>

      <div className="row">
        <img id="home-mockup" src={Green} alt="A mockup of the mobile app home screen" />
        <div className="column">
          <div className="row left">
            <img className="puzz-left" src={PuzzTwo} alt="" />
          </div>

          <span className="employers-header">Easy to Enter</span>
          <p className="center-text">
            Employees who are already registered with SkillProfile can add your code to their
            application to share their existing work skill profiles with you.
          </p>

          <div className="row right">
            <img className="puzz-right" src={PuzzThree} alt="" />
          </div>
        </div>
      </div>

      <div className="column center">
        <span className="employers-header">Organizational Skills Report</span>
        <p className="center-text">
          Use the Organizational Skills Report to understand what skills exist within your workforce
          and identify employees with those skills. Drill down to see the individual employee work
          profiles. Search for skills and employees with the skills you need for a project.
        </p>
        <img
          alt="a mockup of an organizational skills report"
          className="report-mockup"
          src={OrgReport}
        />
        <img
          className="floating-puzzle"
          src={PuzzFour}
          alt="a puzzle piece, its just a part of the background"
        />
      </div>

      <div className="row">
        <div className="column left">
          <span className="employers-header">Individual Skills Report</span>
          <p className="left-text">
            Share the Individual Skills Report with your employee and use it for coaching sessions
            and professional development discussions.
          </p>
          <a
            className="button-long"
            target="_blank"
            rel="noreferrer"
            href="https://calendly.com/kristin-skillprofile/skillprofile-for-employers"
          >
            Book A Demo
          </a>
        </div>
        <img alt="thing" src={WorkProfile} />
      </div>
    </main>
  );
};

export default ForEmployers;
