import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

const ShareArea = (props) => {
  return (
    <section id="share-area">
      Share on:
      <EmailShareButton className="share-area-button" url={"https://skill-profile.com/welcome"}>
        <EmailIcon className="share-area-icon" />
      </EmailShareButton>
      <FacebookShareButton className="share-area-button" url={"https://skill-profile.com/welcome"}>
        <FacebookIcon className="share-area-icon" />
      </FacebookShareButton>
      <LinkedinShareButton className="share-area-button" url={"https://skill-profile.com/welcome"}>
        <LinkedinIcon className="share-area-icon" />
      </LinkedinShareButton>
      <TwitterShareButton className="share-area-button" url={"https://skill-profile.com/welcome"}>
        <TwitterIcon className="share-area-icon" />
      </TwitterShareButton>
    </section>
  );
};

export default ShareArea;
