const GooglePlayButton = () => {
  return (
    <div className="download-button google">
      <a
        href="https://play.google.com/store/apps/details?id=com.skillprofile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="Get it on Google Play"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        />
      </a>
    </div>
  );
};

export default GooglePlayButton;
