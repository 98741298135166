import { useEffect, useState } from "react";
import { getRequest } from "../utilities/services/feedback-service";
import { getFeedbackRequestAttributes } from "../utilities/services/profile-attribute-service";
import { getProfile } from "../utilities/services/profile-service";
import { getUserByUid } from "../utilities/services/user-service";

const useFeedbackRequest = (requestUid) => {
  const [feedbackRequest, setFeedbackRequest] = useState(null);
  const [sender, setSender] = useState(null);
  const [profileAttributes, setProfileAttributes] = useState([]);
  const [ratings, setRatings] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchFeedbackRequest = async (requestId) => {
    const requestData = await getRequest(requestId);
    if (!requestData) {
      console.error(`Feedback request ${requestId} was not found.`);
      throw new Error("Couldn't load the specified feedback request. Check the log for details.");
    }

    return requestData;
  };

  const fetchRequestSender = async (senderUid) => {
    const senderData = await getUserByUid(senderUid);
    if (!senderData) {
      console.error(`Feedback request sent from unknown user ${senderUid}.`);
      throw new Error(
        "Couldn't find the user who sent this feedback request. Check the log for details."
      );
    }

    return senderData;
  };

  const fetchProfile = async (userId, profileUid) => {
    const profileData = await getProfile(userId, profileUid);
    if (!profileData) {
      console.error(`Profile ${profileUid}, belonging to user ${userId}, was not found.`);
      throw new Error("Couldn't load the required profile. Check the log for details.");
    }

    return profileData;
  };

  const fetchProfileAttributes = async (profileUid) => {
    const attributeData = await getFeedbackRequestAttributes(profileUid);
    if (!attributeData) {
      console.error(`Attributes for profile ${profileUid} were not found.`);
      throw new Error(
        "Couldn't load the attributes for the required profile. Check the log for details."
      );
    }

    return attributeData;
  };

  const getRatings = (attributeData) => {
    const ratingsData = attributeData.map(({ rating, name, type, id }) => ({
      rating,
      name,
      type,
      id,
    }));

    return ratingsData;
  };

  useEffect(() => {
    const fetchRequest = async () => {
      setIsLoading(true);

      try {
        const requestData = await fetchFeedbackRequest(requestUid);
        setFeedbackRequest(requestData);

        const [senderData, profileAttributeData] = await Promise.all([
          fetchRequestSender(requestData.UserUid),
          fetchProfileAttributes(requestData.ProfileUid),
        ]);

        const profileData = await fetchProfile(requestData.UserUid, requestData.ProfileUid);

        setSender(senderData);
        setProfile(profileData);
        setProfileAttributes(profileAttributeData);

        const ratingsData = getRatings(profileAttributeData);
        setRatings(ratingsData);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRequest();
  }, [requestUid]);

  return {
    feedbackRequest,
    sender,
    profileAttributes,
    ratings,
    setRatings,
    profile,
    isLoading,
    error,
  };
};

export default useFeedbackRequest;
