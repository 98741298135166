import { db } from "../firebase/firebase-init";

const getProfileAttributes = async (profileUid, mapFn) => {
  try {
    const res = await db
      .collection("ProfileAttributes")
      .where("ProfileUid", "==", profileUid)
      .get();

    const data = res.docs
      .map((doc) => ({ doc: doc, data: doc.data() }))
      .filter((item) => item.data.ProfileUid === profileUid);

    const mappedData = data.map(mapFn);

    return mappedData;
  } catch (error) {
    console.error(
      `profile-attribute-service couldn't load ProfileAttributes for Profile '${profileUid}'`,
      error
    );
    throw error;
  }
};

const getFeedbackRequestAttributes = async (profileUid) => {
  const mapAttributeFn = (item) => {
    const { doc, data } = item;

    const mappedItemData = {
      rating: 1,
      name: data.AttributeName || data.AttributesName,
      type: data.AttributeType.toLowerCase(),
      id: data.AttributeId,
      pid: data.ProfileUid,
      paid: doc.id,
    };

    return mappedItemData;
  };

  const data = await getProfileAttributes(profileUid, mapAttributeFn);

  return data;
};

const getFeedbackResultsAttributes = async (profileUid) => {
  const mapAttributeFn = (item) => item.data;
  const data = await getProfileAttributes(profileUid, mapAttributeFn);

  return data;
};

export { getProfileAttributes, getFeedbackRequestAttributes, getFeedbackResultsAttributes };
