import { firebase } from '@firebase/app';
import 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import Logo from '../images/logo-web.svg';
import UserLineChart from './UserLineChart';
import UsersTable from './UsersTable';

const EmployerDash = (props) => {
  const { currentUser } = props;
  const [userList, setUserList] = useState([]);

  //Eventually this will be changed to where it will only grab users from a specific org
  useEffect(() => {
    firebase
      .firestore()
      .collection('Users')
      .get()
      .then((res) => {
        let data = [];
        debugger;
        for (let i = 0; i < res.docs.length; i++) {
          let newDatum = {
            name: res.docs[i].data().name
              ? res.docs[i].data().name
              : res.docs[i].data().fullName,
            requestsMade: i,
            email: res.docs[i].data().email,
            id: res.docs[i].data().id,
          };
          //= Object.assign({ requestsMade: i}, res.docs[i].data())
          data.push(newDatum);
        }
        setUserList(data);
      });
  }, []);

  return currentUser ? (
    <div className="column center">
      <div className="nav-logo">
        {' '}
        <img alt="look at this logo" src={Logo} />{' '}
      </div>
      <span className="section-header">
        {currentUser.name.slice(-1) === 's'
          ? `${currentUser.name}'`
          : `${currentUser.name}'s`}{' '}
        Dashboard
      </span>
      <UserLineChart />
      <UsersTable data={userList} />
    </div>
  ) : (
    <Redirect to="/login" />
  );
};

export default EmployerDash;
