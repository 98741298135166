import { db } from "../firebase/firebase-init";

const getAllProfiles = async (userId) => {
  try {
    const res = await db.collection("Users").doc(userId).collection("Profiles").get();
    const profiles = res.docs.map((doc) => doc.data());

    return profiles;
  } catch (error) {
    console.error(`profile-service couldn't load the Profiles for User '${userId}'`, error);
    throw error;
  }
};

const getProfile = async (userId, profileId) => {
  try {
    if (userId && profileId) {
      const res = await db
        .collection("Users")
        .doc(userId)
        .collection("Profiles")
        .doc(profileId)
        .get();

      if (res.exists) {
        const profileData = res.data();
        const profile = {
          ...profileData,
          id: profileId,
        };

        return profile;
      }
    }
  } catch (error) {
    console.error(
      `profile-service couldn't load Profile '${profileId}' for User '${userId}'`,
      error
    );
    throw error;
  }
};

export { getAllProfiles, getProfile };
