import { Link } from "react-router-dom";
import TurquoisePuzzle from "../images/puzzle-top-right-turquoise.svg";
import GreenPuzzle from "../images/puzzledarkgreendownard.svg";
import YellowPuzzle from "../images/yellow.svg";

const ListItem = ({ image, title, text }) => {
  return (
    <div className="column center thirds">
      <div className="puzzle-piece-container">
        <img alt="puzzle" src={image} />
      </div>
      <span className="paragraph-header long">{title}</span>
      <p className="paragraph-about">{text}</p>
    </div>
  );
};

const AboutSkillProfile = ({ element }) => {
  return (
    <section
      id="about-skill-profile"
      className="column home-page-column-container center"
      ref={element}
    >
      <span className="subtitle yellow"> How It Works</span>
      <div className="column center">
        <span className="subsection-header">About SkillProfile</span>
        <div className="orange-highlight short" />
      </div>
      <div className="row main-container">
        <ListItem
          image={GreenPuzzle}
          title="Learn More About Yourself"
          text="Did you ever want to give or get honest and actionable feedback from friends and gamer
            buddies without worrying about feelings getting in the way?"
        />
        <ListItem
          image={YellowPuzzle}
          title="Get Anonymous Feedback From Friends"
          text="SkillProfile is a new app that lets you ask your friends for anonymous feedback about
            your skills. Add topics for Knowledge, Skills, Attitudes and Competencies to your
            profile and then request feedback."
        />
        <ListItem
          image={TurquoisePuzzle}
          title="Try Out This New Way of Upskilling!"
          text="See anonymized results personalized to you. Your friends can be honest because they know
            the won't hurt your feelings and you can get truthful and aggregated feedback to improve
            on the things you care about!"
        />
      </div>
      <Link to="/welcome" target="_blank" className="button-long center get-the-app">
        Get The App
      </Link>
    </section>
  );
};

export default AboutSkillProfile;
