import Bubble from "../images/booble.png";
import Cog from "../images/cog.png";
import DefaultUserImage from "../images/default-user.png";

const FeedbackPageProfileHeader = (props) => {
  const imageUrl = props.imageUrl;
  const user = props.user;

  return (
    <section className="orange-topper">
      <article className="profile-header">{user.fullName}'s Profile</article>
      <article className="profile-icons">
        <img className="deco-icon" alt="" src={Bubble} />
        <div
          className="circle-image-container"
          style={{
            backgroundImage: `url(${imageUrl ? imageUrl : DefaultUserImage})`,
          }}
        />
        <img className="deco-icon" alt="" src={Cog} />
      </article>
    </section>
  );
};

export default FeedbackPageProfileHeader;
