import Slider from "@mui/material/Slider";
import { styled } from "@mui/system";

const attributeBgColors = {
  competencies: "71adae",
  knowledge: "ff9200",
  attitudes: "1d5253",
  skills: "ff5018",
};

const getBaseStyles = (attributeTitle) => {
  const bgColor = `#${attributeBgColors[attributeTitle]}`;
  const baseStyles = {
    color: bgColor,
    height: 8,
    marginTop: 3,
    "& .MuiSlider-track": {
      height: 14,
      borderRadius: 7,
    },
    "& .MuiSlider-rail": {
      height: 14,
      color: "#e9f0f0",
      borderRadius: 7,
    },
  };

  return baseStyles;
};

const getInputStyles = (attributeTitle) => {
  const attributeBgColor = attributeBgColors[attributeTitle];
  const bgColor = `#${attributeBgColor}`;
  const bgHexCode = parseInt(attributeBgColor, 16);
  const r = (bgHexCode >> 16) & 0xff;
  const g = (bgHexCode >> 8) & 0xff;
  const b = bgHexCode & 0xff;
  const inputStyles = {
    "& .MuiSlider-thumb": {
      height: 26,
      width: 26,
      backgroundColor: "#fff",
      border: "6px solid currentColor",
      "&:focus, &:hover, &:active": {
        boxShadow: "inherit",
      },
      "&.Mui-focusVisible": {
        boxShadow: `rgba(${r}, ${g}, ${b}, 0.16) 0px 0px 0px 8px`,
      },
      "&.Mui-active": {
        boxShadow: `rgba(${r}, ${g}, ${b}, 0.16) 0px 0px 0px 14px`,
      },
    },
    "& .MuiSlider-valueLabel": {
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#FFF",
      top: 63,
      width: 30,
      height: 30,
      fontSize: "0.8rem",
      "&::before": {
        zIndex: -1,
        backgroundColor: bgColor,
        top: -36,
        width: 28,
        height: 28,
        borderRadius: "0 50% 50% 50%",
        border: `1px solid ${bgColor}`,
        marginTop: 20,
      },
    },
  };

  return inputStyles;
};

const getResultStyles = () => {
  const resultStyles = {
    "& .MuiSlider-thumb": {
      height: 0,
      width: 0,
      visibility: "hidden",
      backgroundColor: "#fff",
      border: "6px solid currentColor",
      marginTop: -6,
      marginLeft: -12,
      "&:focus, &:hover, &.Mui-active": {
        boxShadow: "inherit",
      },
    },
    "& .MuiSlider-valueLabel": {
      left: "calc(-50% + 4px)",
      "&::before": {},
    },
  };

  return resultStyles;
};

const getSliderStyles = (shouldDisplayAsResults, attributeTitle) => {
  const baseStyles = getBaseStyles(attributeTitle);
  const modifierStyles = shouldDisplayAsResults
    ? getResultStyles()
    : getInputStyles(attributeTitle);

  return {
    ...baseStyles,
    ...modifierStyles,
  };
};

const getCustomSlider = (shouldDisplayAsResults, attributeTitle) => {
  const sliderStyles = getSliderStyles(shouldDisplayAsResults, attributeTitle);
  const CustomSlider = styled(Slider)(({ theme }) => sliderStyles);

  return CustomSlider;
};

export default getCustomSlider;
