const constants = {
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyCw83hJHJPs_42-i8cqStwb2nLXJqGxLug",
    authDomain: "skillprofile-acea5.firebaseapp.com",
    projectId: "skillprofile-acea5",
    storageBucket: "skillprofile-acea5.appspot.com",
    messagingSenderId: "1030778412170",
    appId: "1:1030778412170:web:7531def8003e45897d2173",
    measurementId: "G-T2EPPML0Z8",
  },
};

export default constants;
