import AppleStoreButton from "../components/AppleStoreButton";
import GooglePlayButton from "../components/GooglePlayButton";
import NavBar from "../components/NavBar";
import ShareArea from "../components/ShareArea";
import "../stylesheets/welcome.scss";

const NewUserWelcome = (props) => {
  const username = props.location.state?.name ?? "";

  return (
    <>
      <NavBar />
      <section id="welcome">
        <div className="highlight-text-container">
          <span className="section-header">Welcome{username ? `, ${username}` : ""}!</span>
          <div className="orange-highlight" />
        </div>
        <div className="welcome-message">
          Download the app below to create your first SkillProfile®
          <br />
          and start getting feedback today!
        </div>
        <div className="download-buttons">
          <GooglePlayButton />
          <AppleStoreButton />
        </div>
        <div className="apple-only">
          <p>Scan QR Code (Apple Only):</p>
          <div className="qr-code-container">
            <img
              alt="qr-code"
              src="https://tools-qr-production.s3.amazonaws.com/output/apple-toolbox/799929ab67d8198cad05f301c77fc7a9/fba050f60951f9181dbea93fc580af13.png"
            ></img>
          </div>
        </div>
        <ul className="welcome-steps">
          <li>Download the app on your device.</li>
          <li>Create a profile and add your knowledge, skills, attitudes, and competencies.</li>
          <li>
            Invite friends, colleagues, and associates to rate your knowlege, skills, attitudes and
            competencies.
          </li>
          <li>Learn about yourself by reviewing ratings and comments validating your results.</li>
          <li>
            Share with your friends, manager or prospective employers. Create a development plan and
            come back and survey once you've practiced.
          </li>
        </ul>
        <p className="welcome-copy">
          It's a completely new way of understanding and measuring your strengths and weaknesses and
          managing your progress! <br />
        </p>
        <ShareArea />

        <p className="welcome-copy">&nbsp;</p>
        <p className="welcome-copy">&nbsp;</p>
      </section>
    </>
  );
};

export default NewUserWelcome;
