import { Modal } from "@mui/material";
import { useState } from "react";
import Loader from "react-loader-spinner";
import ErrorMessage from "../components/ErrorMessage";
import FeedbackPageAttributeList from "../components/FeedbackPageAttributeList";
import FeedbackPageLogo from "../components/FeedbackPageLogo";
import FeedbackPageProfileHeader from "../components/FeedbackPageProfileHeader";
import useFeedbackRequest from "../hooks/useFeedbackRequest";
import { submitFeedback } from "../utilities/services/feedback-service";

const UnauthorizedFeedback = (props) => {
  const requestId = props.match.params.requestId;
  const {
    feedbackRequest,
    sender,
    profileAttributes,
    ratings,
    setRatings,
    profile,
    isLoading,
    error,
  } = useFeedbackRequest(requestId);
  const [comment, setComment] = useState("");
  const [shouldIncludeFullComment, setShouldIncludeFullComment] = useState(false);

  const handleSliderChange = (e, newValue, index) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [index]: {
        rating: newValue,
        name: ratings[index].name,
        type: ratings[index].type,
        id: ratings[index].id,
      },
    }));
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await submitFeedback(
      feedbackRequest,
      sender,
      profileAttributes,
      ratings,
      shouldIncludeFullComment,
      comment
    );

    if (result.success) {
      props.history.push("/thankyou");
    }
  };

  return (
    <main id="feedback-request" className="flex-column align-items-center">
      <FeedbackPageLogo />
      {isLoading ? (
        <Loader type="Circles" color="#FF5018" height={180} width={180} />
      ) : error ? (
        <Modal open={!!error}>
          <div>
            <ErrorMessage message={error.message} color="black" />
          </div>
        </Modal>
      ) : (
        <>
          <FeedbackPageProfileHeader user={sender} imageUrl={profile.imageUrl} />
          <form className="feedback-attribute-list">
            {profileAttributes && ratings && (
              <FeedbackPageAttributeList
                attributes={profileAttributes}
                ratings={ratings}
                user={sender}
                handleSliderChange={handleSliderChange}
              />
            )}
            <textarea
              className="feedback-comment"
              onChange={handleCommentChange}
              value={comment}
              placeholder="Write your comment..."
            />
            <div className="show-exact-comments">
              <input
                type="checkbox"
                checked={shouldIncludeFullComment}
                onChange={(e) => setShouldIncludeFullComment((previousValue) => !previousValue)}
              />
              <span>
                Do you want to show your exact comments? Otherwise comments will be grouped together
                with other responses.
              </span>
            </div>
            <div className="button-long" id="submit" onClick={handleSubmit}>
              Submit
            </div>
          </form>
        </>
      )}
    </main>
  );
};

export default UnauthorizedFeedback;
