import { useEffect, useState } from "react";
import { getFeedbackResultsAttributes } from "../utilities/services/profile-attribute-service";
import { getProfile } from "../utilities/services/profile-service";
import { getUserById } from "../utilities/services/user-service";

const useFeedbackResults = (userId, profileUid) => {
  const [profileAttributes, setProfileAttributes] = useState([]);
  const [sender, setSender] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchProfileAttributes = async (profileUid) => {
    const attributeData = await getFeedbackResultsAttributes(profileUid);
    if (!attributeData) {
      console.error(`Attributes for profile ${profileUid} were not found.`);
      throw new Error(
        "Couldn't load the attributes for the required profile. Check the log for details."
      );
    }

    return attributeData;
  };

  const fetchRequestSender = async (senderId) => {
    const senderData = await getUserById(senderId);
    if (!senderData) {
      console.error(`Feedback request sent from unknown user ${senderId}.`);
      throw new Error(
        "Couldn't find the user who sent this feedback request. Check the log for details."
      );
    }

    return senderData;
  };

  const fetchProfile = async (userId, profileId) => {
    const profile = await getProfile(userId, profileId);

    if (!profile) {
      console.error(`No profiles found for user ${userId}.`);
      throw new Error("Couldn't find any profiles for this user. Check the log for details.");
    }

    return profile;
  };

  useEffect(() => {
    const fetchRequest = async () => {
      setIsLoading(true);

      try {
        const profileAttributeData = await fetchProfileAttributes(profileUid);
        setProfileAttributes(profileAttributeData);

        const senderData = await fetchRequestSender(userId);
        setSender(senderData);

        const profile = await fetchProfile(userId, profileUid);
        const imageUrl = profile?.imageUrl;

        setImageUrl(imageUrl);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRequest();
  }, [userId, profileUid]);

  return {
    sender,
    profileAttributes,
    imageUrl,
    isLoading,
    error,
  };
};

export default useFeedbackResults;
