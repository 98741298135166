import { Modal } from "@mui/material";
import Loader from "react-loader-spinner";
import ErrorMessage from "../components/ErrorMessage";
import FeedbackPageAttributeList from "../components/FeedbackPageAttributeList";
import FeedbackPageLogo from "../components/FeedbackPageLogo";
import FeedbackPageProfileHeader from "../components/FeedbackPageProfileHeader";
import useFeedbackResults from "../hooks/useFeedbackResults";

const Results = (props) => {
  const { userId, profileUid } = props.match.params;
  const { profileAttributes, sender, imageUrl, isLoading, error } = useFeedbackResults(
    userId,
    profileUid
  );

  return (
    <main id="feedback-results" className="flex-column align-items-center">
      <FeedbackPageLogo />
      {isLoading ? (
        <Loader type="Circles" color="#FF5018" height={180} width={180} />
      ) : error ? (
        <Modal open={!!error}>
          <div>
            <ErrorMessage message={error.message} color="black" />
          </div>
        </Modal>
      ) : (
        <>
          <FeedbackPageProfileHeader user={sender} imageUrl={imageUrl} />
          <section className="feedback-attribute-list">
            <FeedbackPageAttributeList
              attributes={profileAttributes}
              handleSliderChange={() => false}
              user={sender}
              shouldDisplayAsResults={true}
            />
          </section>
        </>
      )}
    </main>
  );
};

export default Results;
