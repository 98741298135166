import { Link } from "react-router-dom";
import Illustration from "../images/illustration.svg";

const Top = ({ element }) => {
  return (
    <>
      <section id="top" className="row top__row" ref={element}>
        <div className="column">
          <span className="section-header">It's Your Future.</span>
          <div className="highlight-text-container">
            <span className="section-header">Own It.</span>
            <div className="orange-highlight" />
          </div>
          <p className="home-page__copy">
            Lean into your strengths by getting anonymous ratings and feedback from people you trust
            about your knowledge, skills and attitudes.
          </p>
          <p className="home-page__copy">
            We’re committed to helping people develop and grow individually, either through work or
            play.
          </p>
          <div className="top__button-container">
            <Link target="_blank" to="/welcome" className="button-short get-the-app">
              Get The App
            </Link>
            <a
              className="button-short reversed"
              href="https://youtu.be/CSz1ZYKvqvY"
              target="_blank"
              rel="noreferrer"
            >
              {"How It Works"}
            </a>
            {/* "For Employers" is hidden until feature work is completed */}
            {/*
            <Link to="/" className="button-short reversed">
              For Employers
            </Link>
            */}
          </div>
        </div>
        <div className="top__image">
          <img alt="Man using SkillProfile on a tablet" src={Illustration} />
        </div>
      </section>
      <section className="top__spacer">&nbsp;</section>
    </>
  );
};

export default Top;
